import React from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

const withUrlProps = initialFilters => WrappedComponent => withRouter((props) => {
  const searchProps = queryString.parse(window.location.search, { arrayFormat: 'bracket' });

  const filters = Object.keys(searchProps)
    .map(key => [key, searchProps[key] === 'false' ? false : searchProps[key]])
    .reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {});
  const saveFilters = (newFilters) => {
    const {
      replace,
      location: { pathname },
    } = props.history;
    const nonEmpty = Object.keys(newFilters)
      .map(key => [key, newFilters[key]])
      .filter(([, value]) => !(value === undefined || value === '' || value === null))
      .reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {});

    const search = queryString.stringify(nonEmpty, { arrayFormat: 'bracket' });
    replace({ pathname: `/${pathname.split('/')[1]}`, search });
  };

  return (
    <WrappedComponent
      filters={{
        ...initialFilters,
        ...filters,
      }}
      {...props}
      saveFilters={saveFilters}
    />
  );
});

export default withUrlProps;
