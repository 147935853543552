import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';

const conditions = (blockId) => {
  const blockIds = !Array.isArray(blockId) ? [blockId] : blockId;
  const elems = blockIds.map(id => `{ field: "field_block_id", value: "${id}" }`);
  return `conditions: [${elems.join(',')}]`;
};

const withFile = WrappedComponent => ({ blockId, ...props }) => {
  const FILE_QUERY = gql`
  query {
    blockContentQuery(filter: { conjunction: OR, ${conditions(blockId)} } ) {
      entities {
        ... on BlockContentFileBlock {
          id
          fieldBlockId
          fieldPlik {
            entity {
              id: fid
              url
            }
          }
        }
      }
    }
  }
`;
  return (
    <Query query={FILE_QUERY}>
      {({ data, loading }) => {
        const files = _.get(data, 'blockContentQuery.entities', undefined);
        return <WrappedComponent {...props} loading={loading} files={files} />;
      }}
    </Query>
  );
};

export default withFile;
