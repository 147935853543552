export function getLines(ctx, text, maxWidth) {
  const words = text.split(' ');
  const lines = [];
  let currentLine = words[0];

  for (let i = 1; i < words.length; i += 1) {
    const word = words[i];
    if (word === '\n') {
      lines.push(currentLine);
      currentLine = '';
    } else {
      const { width } = ctx.measureText(`${currentLine} ${word}`);
      if (width < maxWidth) {
        currentLine = `${currentLine} ${word}`;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
  }
  lines.push(currentLine);
  return lines;
}
