import React, { useEffect, useState } from 'react';
import injectStyles from 'react-jss';
import { withRouter } from 'react-router-dom';
import ItemWithDetails from './ItemWithDetails';
import withQuery from './withQuery';
import ImagePreview from './ImagePreview';

const styles = ({ brakePoint: { mobile } }) => ({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    position: 'relative',
    margin: '0 50px',
    [mobile]: {
      flexFlow: 'row nowrap',
      overflowY: 'hidden',
      overflowX: 'scroll',
      WebkitOverflowScrolling: 'touch',
      width: '100vw',
      position: 'relative',
      left: '-40px',
      margin: '0',
      right: '0px',
    },
  },
});

const ProgramList = injectStyles(styles)(
  ({
    classes, events, match: { params }, history, location: { search },
  }) => {
    const [modalId, setModalId] = useState(false);

    const onClick = itemId => () => {
      if (params.id === itemId) {
        history.push(`/program${search}`);
      } else {
        history.push(`/program/${itemId}${search}`);
      }
    };

    const setModal = itemId => () => {
      setModalId(itemId);
    };

    useEffect(() => {
      setTimeout(() => {
        if (document.getElementById(params.id)) document.getElementById(params.id).scrollIntoView(true);
      }, 50);
    });

    return (
      <div className={classes.root}>
        <ImagePreview isOpen={!!modalId} id={params.id} handleClose={setModal(false)} />
        {events.map(event => (
          <ItemWithDetails
            openModal={setModal(event.id)}
            key={event.id}
            id={event.id}
            onClick={onClick(event.id)}
            active={params.id === event.id}
            data={event}
          />
        ))}
      </div>
    );
  },
);

export default withQuery(withRouter(ProgramList));
