import React, { useEffect, useState } from 'react';
import injectStyles from 'react-jss';
import _ from 'lodash';
import classnames from 'classnames';
import arrowUp from '../../assets/scroll-arrow-up.svg';
import BlankButton from '../styledElements/BlankButton';

const styles = ({
  brakePoint: { mobile },
  palette: { red, beige, green },
  transition: { duration, effect },
}) => ({
  root: {
    color: beige,
    backgroundColor: red,
    position: 'fixed',
    right: 20,
    bottom: -100,
    zIndex: 10,
    width: 50,
    height: 50,
    display: 'flex',
    flexFlow: 'column',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 0 6px 0px #ababab',
    willChange: 'background-color',
    transition: `bottom ${duration} ${effect}`,
    '&:hover': {
      transition: `background-color ${duration} ${effect}`,
      backgroundColor: green,
    },
    [mobile]: {
      display: 'none',
    },
  },
  visible: {
    bottom: 80,
    willChange: 'bottom',
    transition: `bottom ${duration} ${effect}`,
  },
  arrow: {
    margin: '-10px 0',
  },
});
const ScrollTop = injectStyles(styles)(({ classes }) => {
  const [visible, set] = useState(false);
  const handleScroll = _.debounce(() => set(window.scrollTop > 400));
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
    };
  });
  const doScroll = () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  return (
    <BlankButton
      className={classnames(classes.root, visible && classes.visible)}
      onClick={doScroll}
    >
      <img className={classes.arrow} src={arrowUp} alt="arrow up" />
      <img className={classes.arrow} src={arrowUp} alt="arrow up" />
      <img className={classes.arrow} src={arrowUp} alt="arrow up" />
    </BlankButton>
  );
});

export default ScrollTop;
