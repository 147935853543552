import React from 'react';
import Dropdown from '../Dropdown';
import withDatesQuery from './withDatesQuery';

const DatesDropdown = ({
  className, value, handleChange, name, dateOptions,
}) => (
  <Dropdown
    multi
    name={name}
    className={className}
    value={value || []}
    handleChange={handleChange}
    options={dateOptions}
  />
);

export default withDatesQuery(DatesDropdown);
