import _ from 'lodash';
import React from 'react';
import injectStyles from 'react-jss';
import LinkButton from './styledElements/LinkButton';
import Typography from './styledElements/Typography';
import withFile from './withFiles';
import Divider from './styledElements/Divider';
import SocialLinks from './SocialLinks';
import { updatedAtYear } from '../config.json';

const styles = ({
  palette: { green },
  font: {
    size: { tiny },
  },
  brakePoint: { tablet, mobile },
}) => ({
  root: {
    width: 'auto',
    height: 80,
    backgroundColor: green,
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px',
    alignItems: 'flex-start',
    margin: '0 60px',
    [tablet]: {
      margin: 0,
    },
    [mobile]: {
      flexFlow: 'column nowrap',
      height: 150,
      justifyContent: 'flex-start',
    },
  },
  inlineContainer: {
    display: 'flex',
    fontSize: tiny,
    flexFlow: 'row nowrap',
    alignItems: 'center',
    [mobile]: {
      flexFlow: 'row wrap',
    },
  },
});

const RegulaminLink = withFile(({ files }) => (
  <LinkButton newTab to={_.get(files, '[0].fieldPlik.entity.url', '')}>
    regulamin
  </LinkButton>
));

const Footer = injectStyles(styles)(({ classes }) => {
  return (
    <>
      <Divider hidden height={120} />
      <div className={classes.root}>
        <SocialLinks />
        <div className={classes.inlineContainer}>
          <RegulaminLink blockId="pdf-regulamin-festiwalu" />
          <span>&nbsp;</span>
          {'|'}
          <span>&nbsp;</span>
          <Typography font="arial" size="tiny">
            Copyright © {updatedAtYear} Stowarzyszenie „Otwarte Mieszkania“
          </Typography>
        </div>
      </div>
    </>
  );
});

export default Footer;
