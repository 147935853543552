import React from 'react';
import injectStyles from 'react-jss';
import { ExternalLinkButton } from './styledElements/LinkButton';

const styles = ({
  font: {
    size: { tiny },
  },
}) => ({
  root: {
    display: 'flex',
    fontSize: tiny,
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
});
const SocialLinks = injectStyles(styles)(({ classes, style }) => (
  <div style={style} className={classes.root}>
    <ExternalLinkButton newTab to="https://www.facebook.com/festiwalotwartemieszkaniakrakow/">
      facebook
    </ExternalLinkButton>
    <span>&nbsp;</span>
    {'>'}
    <span>&nbsp;</span>
    <ExternalLinkButton newTab to="https://www.instagram.com/fomkrakow/">
      instagram
    </ExternalLinkButton>
    <span>&nbsp;</span>
    {'>'}
    <span>&nbsp;</span>
    <ExternalLinkButton newTab to="https://vimeo.com/user94361276">
      vimeo
    </ExternalLinkButton>
  </div>
));

export default SocialLinks;
