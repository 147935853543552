import React, { useState } from 'react';
import Lightbox from 'react-images';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';

const IMAGES_QUERY = gql`
  query ImagesQuery($uuid: [String]) {
    nodeQuery(limit: 1, filter: { conditions: [{ field: "uuid", value: $uuid }] }) {
      entities {
        ... on NodeEvent {
          uuid
          fieldName
          photos: fieldPhoto {
            id: targetId
            url
          }
        }
      }
    }
  }
`;

const ImagePreview = ({ isOpen, id, handleClose }) => {
  const [currentImage, setImage] = useState(0);
  const onClose = (e) => {
    setImage(0);
    handleClose(e);
  };
  return (
    <Query query={IMAGES_QUERY} variables={{ uuid: id }}>
      {({ data, loading }) => {
        const images = _.get(data, 'nodeQuery.entities[0]');
        if (loading || !images || !Array.isArray(images.photos)) return null;
        const imagsMap = images.photos.map(({ url }) => ({
          src: url,
          caption: images.fieldName,
        }));
        return (
          <Lightbox
            // backdropClosesModal
            width={960}
            currentImage={currentImage}
            onClickNext={() => setImage(currentImage + 1)}
            onClickPrev={() => setImage(currentImage - 1)}
            images={imagsMap}
            onClose={onClose}
            imageCountSeparator=" z "
            isOpen={isOpen}
            preventScroll
          />
        );
      }}
    </Query>
  );
};

export default ImagePreview;
