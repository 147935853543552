import React, { useState } from 'react';
import injectStyles from 'react-jss';
import classnames from 'classnames';
import _ from 'lodash';
import Typography from './styledElements/Typography';
import BlankButton from './styledElements/BlankButton';
import closeIcon from '../assets/closeIcon.svg';
import doneIcon from '../assets/doneIcon.svg';

const styles = ({
  palette: { black, green, background },
  brakePoint: { mobile },
  font: {
    family: { raleway },
  },
}) => ({
  root: {
    position: 'relative',
    whiteSpace: 'nowrap',
    [mobile]: {
      whiteSpace: 'normal',
      overflow: 'scroll',
      WebkitOverflowScrolling: 'touch',
    },
  },
  overlap: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1000,
  },
  listContainer: {
    zIndex: 1010,
    top: -20,
    left: -20,
    position: 'absolute',
    padding: 30,
    backgroundColor: green,
    border: `1px solid ${black}`,
    [mobile]: {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      padding: '60px 20px 70px',
      overflow: 'hidden',
    },
  },
  list: {
    listStyle: 'none',
    margin: 0,
    marginBlockEnd: 0,
    marginBlockStart: 0,
    padding: 0,
    [mobile]: {
      overflow: 'auto',
      height: '100%',
    },
  },
  listElem: {
    userSelect: 'none',
    cursor: 'pointer',
    borderBottom: `1px solid ${black}`,
    padding: '8px 8px',
    '&:first-child': {
      borderTop: `1px solid ${black}`,
      [mobile]: {
        borderTop: 'none',
      },
    },
    '&:hover': {
      backgroundColor: background,
      [mobile]: {
        backgroundColor: green,
      },
    },
    [mobile]: {
      fontSize: '6.1vw',
      fontFamily: raleway,
      textAlign: 'center',
      borderBottom: 'none',
    },
  },
  selected: {
    backgroundColor: background,
    [mobile]: {
      backgroundColor: green,
      color: background,
    },
  },
  closeButton: {
    position: 'absolute',
    top: '15px',
    left: '25px',
    height: '40px',
    display: 'none',
    [mobile]: {
      display: 'initial',
    },
  },
  doneButton: {
    position: 'absolute',
    top: '15px',
    right: '25px',
    height: '40px',
    display: 'none',
    [mobile]: {
      display: 'initial',
    },
  },
});

const Dropdown = injectStyles(styles)(
  ({
    classes, value: values, options, className, open, handleChange, name, multi,
  }) => {
    const [isOpen, setOpen] = useState(open);
    const handleMenuClose = () => {
      document.getElementById('cover').remove();
      setOpen(false);
    };
    const handleSelect = itemId => () => {
      if (multi) {
        if (itemId === '') return handleChange({ target: { value: [], name } });
        return handleChange({ target: { value: _.xor(values, [itemId]), name } });
      }
      handleChange({ target: { value: itemId, name } });
      return handleMenuClose();
    };
    const handleMenuOpen = () => {
      const cover = document.createElement('div');
      cover.classList.add(classes.overlap);
      cover.id = 'cover';
      cover.onclick = handleMenuClose;
      document.querySelector('#contentContainer').appendChild(cover);
      setOpen(true);
    };
    const handleCancel = () => {
      handleSelect('')();
      handleMenuClose();
    };
    const selected = new Set(Array.isArray(values) ? values : [values]);
    return (
      <div className={classnames(classes.root, className)}>
        <BlankButton onClick={handleMenuOpen}>
          <Typography font="arial" size="tiny">
            {(multi
              ? _.intersectionWith(options, values, ({ id }, val) => id === val)
                .map(({ value }) => value)
                .join(', ')
              : options.find(({ id }) => `${id}` === `${values}`).value) || '- Wszystkie -'}
          </Typography>
        </BlankButton>
        {isOpen && (
          <div className={classes.listContainer}>
            <BlankButton onClick={handleCancel} className={classes.closeButton}>
              <img style={{ height: '100%' }} src={closeIcon} alt="close icon" />
            </BlankButton>
            <BlankButton onClick={handleMenuClose} className={classes.doneButton}>
              <img style={{ height: '100%' }} src={doneIcon} alt="done icon" />
            </BlankButton>
            <ul className={classes.list}>
              {options.map(option => (
                <li
                  key={option.id}
                  onClick={handleSelect(`${option.id}`)}
                  className={classnames(
                    classes.listElem,
                    selected.has(option.id) && classes.selected,
                  )}
                >
                  {option.value}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  },
);

export default Dropdown;
