import React, { useState } from 'react';
import injectStyles from 'react-jss';
import classnames from 'classnames';
import { useSpring, animated } from 'react-spring';
import _ from 'lodash';
import Typography from '../styledElements/Typography';
import { ExternalLinkButton, InternalLinkButton } from '../styledElements/LinkButton';
import missingimg from '../../assets/missingimg.png';
import BlankButton from '../styledElements/BlankButton';

const styles = ({
  font: {
    size: { tiny, small },
    family: { raleway },
  },
  palette: {
    black, green, bordo, red, beige, background,
  },
  brakePoint: { mobile, tablet },
}) => ({
  root: {
    width: 530,
    border: 'none',
    WebkitAppearance: 'none',
    outline: 'none',
    textAlign: 'left',
    padding: '0',
    cursor: 'pointer',
    margin: '20px 0',
    paddingLeft: 30,
    backgroundColor: background,
    position: 'relative',
    color: black,
    zIndex: 1,
    '&:hover': {
      outline: 'none',
    },
    '&:active': {
      color: black,
    },
    [mobile]: {
      width: '80vw',
      padding: '20px',
      margin: '0',
    },
  },
  inlineContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  arrow: {
    height: 9,
    marginRight: 5,
  },
  dateContiner: {
    marginRight: 0,
    marginLeft: 'auto',
    justifyContent: 'flex-end',
  },
  events: {
    color: black,
    fontSize: small,
  },
  row: {
    justifyContent: 'flex-start',
    [mobile]: {
      flexFlow: 'column',
    },
  },
  col1: {
    position: 'relative',
    width: '30%',
    alignSelf: 'flex-start',
    [mobile]: {
      fontSize: '0px',
      width: 'calc(100% - 10px)',
      marginLeft: '10px',
    },
  },
  col2: {
    width: '70%',
    marginLeft: 10,
    height: 162,
    overflow: 'hidden',
    [mobile]: {
      maxWidth: '100%',
      width: '100%',
      textAlign: 'justify',
      margin: '30px 0',
      maxHeight: '1000px',
    },
  },
  imageWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.7,
    backgroundColor: green,
    zIndex: 1,
    '&:hover': {
      backgroundColor: bordo,
    },
  },
  image: {
    maxWidth: '100%',
    maxHeight: 190,
    boxShadow: `-10px 10px 0px 0px ${green}`,
    [mobile]: {
      maxHeight: '400px',
      maxWidth: '100%',
      boxShadow: `-10px 10px 0px 0px ${green}, -10px -10px 0px 0px ${green}`,
      position: 'relative',
      left: '50%',
      transform: 'translate(-50%)',
    },
  },
  timeContainer: {
    position: 'absolute',
    borderRadius: '50%',
    width: 55,
    height: 55,
    backgroundColor: red,
    top: -20,
    zIndex: 2,
    left: -30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  timeText: {
    fontFamily: raleway,
    fontSize: 18,
    transform: 'translateY(-3px)',
    color: beige,
  },
  fullStyle: {
    padding: '0px 10px 10px 0px',
    overflow: 'auto',
    height: 'auto',
    [mobile]: {
      overflow: 'visible',
      padding: 0,
      height: 'auto',
      margin: '30px 0 0 0',
    },
  },
  widther: {
    width: "100%",
    margin: "20px 0 5px 0",
    paddingLeft: 10,
    [mobile]: {
      width: '85vw',
    },
  },
  col1FullWidth: {
    marginBottom: 25,
  },
  imageFullWidth: {
    height: 210,
    maxHeight: 210,
    width: "100%",
    display: "block",
    backgroundPosition: "top center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    boxShadow: "none",
  },
  rezerwuj: {
    position: 'absolute',
    bottom: '-30px',
    left: '-12px',
  },
  zobaczWiecej: {
    position: 'absolute',
    bottom: '-20px',
    right: '30px',
  },
  searchIcon: {
    position: 'absolute',
    bottom: -27,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderRadius: 10,
    fontSize: tiny
  }
});

const Item = injectStyles(styles)(
  ({
    classes, active, fullHeight, onClick = null, data, theme, openModal,
  }) => {
    const [hovered, setHover] = useState(false);
    const onHover = () => setHover(true);
    const onHoverEnd = () => setHover(false);
    const {
      palette: { red, green },
    } = theme;
    const preventClick = e => e.stopPropagation();

    const { backgroundColor } = fullHeight
      ? { backgroundColor: 'transparent' }
      : useSpring({ backgroundColor: active || hovered ? red : green });

    return (
      <button
        onClick={onClick}
        onMouseEnter={onHover}
        onMouseLeave={onHoverEnd}
        type="button"
        className={classnames(classes.root, fullHeight && classes.widther)}
      >
        <div className={classnames(classes.inlineContainer, classes.row)}>
          <div style={{ fontSize: 0 }} className={classnames(classes.col1, fullHeight && classes.col1FullWidth)}>
            {fullHeight ? (
              <span
                alt={data.name}
                className={classnames(classes.image, classes.imageFullWidth)}
                style={{
                  backgroundImage: `url(${_.get(data, "photos[0].url", missingimg)}`
                }}
              />
            ) : (
              <img
                className={classes.image}
                src={_.get(data, "photos[0].url", missingimg)}
                alt={data.name}
              />
            )}
            <animated.div onClick={fullHeight ? openModal : null} style={{ backgroundColor }} className={classes.imageWrapper} />
            {fullHeight && <BlankButton onClick={openModal} className={classes.searchIcon}>{'< zdjęcia >'}</BlankButton>}
            {data.fieldRegistrationLink && (
              <ExternalLinkButton
                newTab
                onClick={preventClick}
                className={classes.rezerwuj}
                to={data.fieldRegistrationLink}
              >
                {'< rezerwuj >'}
              </ExternalLinkButton>
            )}
          </div>
          <div className={classnames(classes.col2, fullHeight && classes.fullStyle)}>
            <Typography font="minion" size="medium">
              {data.name}
            </Typography>
            <Typography font="minion" size={fullHeight ? "small" : "tiny"}>
              {data.description}
            </Typography>
          </div>
        </div>
        {!fullHeight && (
          <InternalLinkButton className={classes.zobaczWiecej}>
            {'< zobacz więcej >'}
          </InternalLinkButton>
        )}
      </button>
    );
  },
);

export default Item;
