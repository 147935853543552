import React from 'react';
import injectStyles from 'react-jss';
import BlockContainer from '../BlockContainer';
import Breadcrumbs from '../Breadcrumbs';
import CenterContainer from '../CenterContainer';
import CircleIcon from '../CircleIcon';
import ProgramLink from '../ProgramLink';
import Divider from '../styledElements/Divider';
import TrimmedContainer from '../styledElements/TrimmedContainer';
import Typography from '../styledElements/Typography';
import TitleContainer from '../TitleContainer';
import { updatedAtYear } from '../../config.json'

const styles = ({ brakePoint: { mobile } }) => ({
  root: {},
  centerContainer: {
    marginTop: 40,
    marginBottom: 40,
  },
  circleIcon: {
    top: -35,
    left: -120,
    [mobile]: {
      position: 'initial',
      shapeOutside: 'ellipse()',
      float: 'left',
      marginRight: '20px',
      marginBottom: '10px',
    },
  },
  blockWithCircleContainer: {
    maxWidth: 400,
    position: 'relative',
  },
  summaryOverwriteText: {},
  summaryOverwriteContianer: {
    maxWidth: 380,
  },
  inlineContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    maxWidth: 700,
    width: '100%',
    flexFlow: 'row wrap',
  },
  giantFont: {
    lineHeight: 0.4,
  },
  columnContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    [mobile]: {
      marginBottom: 20,
      textAlign: 'justify',
    },
  },
});

const Stowarzyszenie = ({ classes }) => (
  <div id="Stowarzyszenie">
    <Breadcrumbs
      primary
      heading="O nas"
      sections={[
        { val: 'Stowarzyszenie', selected: true },
        { val: 'Zespół' },
        { val: 'Wolontariusze' },
      ]}
    />
    <CenterContainer className={classes.centerContainer}>
      <TrimmedContainer>
        <div className={classes.blockWithCircleContainer}>
          <ProgramLink customText={<CircleIcon className={classes.circleIcon} />} />
          <BlockContainer blockId="opis_o_stowarzyszeniu" />
        </div>
      </TrimmedContainer>
      <Divider hidden height={90} />
      <div className={classes.inlineContainer}>
        <div className={classes.columnContainer}>
          <TitleContainer
            classes={{
              root: classes.summaryOverwriteContianer,
              text: classes.summaryOverwriteText,
            }}
            blockId="summary-onas"
          />
          <ProgramLink />
        </div>
        <Typography className={classes.giantFont} size="giant" font="questa">
          {updatedAtYear}
        </Typography>
      </div>
      <Divider hidden height={90} />
    </CenterContainer>
  </div>
);

export default injectStyles(styles)(Stowarzyszenie);
