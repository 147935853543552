import React from 'react';
import injectStyles from 'react-jss';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const styles = ({ font: { size, family }, brakePoint: { mobile } }) => ({
  tiny: {
    fontSize: size.tiny,
  },
  small: {
    fontSize: size.small,
  },
  medium: {
    fontSize: size.medium,
    [mobile]: {
      fontSize: size[mobile].medium,
    },
  },
  large: {
    fontSize: size.large,
  },
  giant: {
    fontSize: size.giant,
    [mobile]: {
      fontSize: size[mobile].giant,
    },
  },
  arial: {
    fontFamily: family.arial,
  },
  minion: {
    fontFamily: family.minion,
    lineHeight: 1.25,
  },
  questa: {
    fontFamily: family.questa,
  },
  raleway: {
    fontFamily: family.raleway,
  },
  italic: {
    fontStyle: 'italic',
  },
});

const Typography = injectStyles(styles)(
  ({
    children, size, font, classes, className, italic, theme, ...rest
  }) => (
    <div
      className={classnames(classes[size], classes[font], italic && classes.italic, className)}
      {...rest}
    >
      {children}
    </div>
  ),
);

Typography.propTypes = {
  font: PropTypes.oneOf(['arial', 'minion', 'questa', 'raleway']),
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large', 'giant']),
  italic: PropTypes.bool,
};

Typography.defaultProps = {
  font: 'arial',
  size: 'tiny',
  italic: false,
};

export default Typography;
