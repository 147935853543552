import React from 'react';
import injectStyles from 'react-jss';
import moment from 'moment';
import classnames from 'classnames';
import _ from 'lodash';
import withQuery from './withQuery';
import Typography from '../../styledElements/Typography';
import ArrowIcon from '../../styledElements/ArrowIcon';
import { ExternalLinkButton, InternalLinkButton } from '../../styledElements/LinkButton';

const styles = ({ palette: { black }, brakePoint: { mobile } }) => ({
  root: {
    marginBottom: 50,
  },
  underline: {
    borderBottom: `1px solid ${black}`,
    padding: '5px 0',
  },
  underlineElem: {
    borderBottom: `1px solid ${black}`,
    padding: '5px 0',
    [mobile]: {
      borderBottom: 'none',
    },
  },
  padded: {
    paddingLeft: 60,
    [mobile]: {
      paddingLeft: 0,
    },
  },
  arrow: {
    transform: 'rotate(-90deg)',
    width: 15,
    height: 15,
    margin: '0 10px',
    [mobile]: {
      transform: 'rotate(-90deg) translateX(-4px)',
    },
  },
  elem: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-start',
    [mobile]: {
      alignItems: 'flex-start',
    },
  },
  inline: {
    flex: '1 1 auto',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    [mobile]: {
      flexFlow: 'column nowrap',
      alignItems: 'flex-start',
    },
  },
  rightInline: {
    width: 200,
    display: 'flex',
    flexFlow: 'row-reverse nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    [mobile]: {
      flexFlow: 'column nowrap',
      alignItems: 'flex-end',
      marginTop: 5,
      width: '100%',
    },
  },
  alignLeft: {
    [mobile]: {
      alignSelf: 'flex-start',
    },
  },
  column: {
    display: 'flex',
    width: '100%',
    flexFlow: 'column nowrap',
  },
  topPadding: {
    marginTop: 4,
    display: 'flex',
    alignItems: 'center',
    width: 80,
  },
});

const Harmonogram = injectStyles(styles)(
  withQuery(({ classes, elems }) => elems.map(([date, hours]) => (
    <div key={date} className={classes.root}>
      <div className={classes.underline}>
        <Typography font="raleway" size="small">
          {date}
        </Typography>
      </div>
      <div className={classes.padded}>
        {hours.map((hour) => {
          if (
            !hour.fieldWydarzenia
              || !hour.fieldWydarzenia[0]
              || !hour.fieldWydarzenia[0].entity
          ) return null;
          const wydarzenia = hour.fieldWydarzenia.map(({ entity }) => entity);
          return (
            <div
              key={`${wydarzenia[0].id}${date}`}
              className={classnames(classes.underlineElem, classes.elem)}
            >
              <div className={classes.topPadding}>
                <Typography font="raleway" size="small">
                  {moment.utc(hour.fieldDate.date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}
                </Typography>
                <ArrowIcon classes={{ root: classes.arrow }} />
              </div>
              <div className={classes.column}>
                {wydarzenia.map(w => (
                  <div key={w.id} className={classes.inline}>
                    <InternalLinkButton to={`/program/${w.id}`}>
                      <Typography
                        style={{ transform: 'translateY(3px)' }}
                        font="minion"
                        size="small"
                      >
                        {w.fieldName}
                      </Typography>
                    </InternalLinkButton>
                    <div className={classes.rightInline}>
                      {_.get(w, 'fieldRegistrationLink.uri') && (
                        <ExternalLinkButton to={_.get(w, 'fieldRegistrationLink.uri')}>
                          {'< rezerwuj >'}
                        </ExternalLinkButton>
                      )}
                      {w.fieldDistrict && (
                        <Typography className={classes.alignLeft} font="arial" size="tiny">
                          {w.fieldDistrict.entity.name}
                        </Typography>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ))),
);

export default Harmonogram;
