import React from 'react';
import injectStyles from 'react-jss';
import withBlockQuery from './withBasicBlockQuery';
import Typography from '../styledElements/Typography';

const styles = ({ brakePoint: { mobile } }) => ({
  root: {
    maxWidth: 500,
    margin: '0 auto',
  },
  pre: {
    whiteSpace: 'pre-wrap',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    margin: 0,
    [mobile]: {
      textAlign: 'justify',
    },
  },
});

const Block = injectStyles(styles)(({ text, classes }) => (
  <Typography className={classes.root} font="minion" size="tiny">
    <pre className={classes.pre}>{text}</pre>
  </Typography>
));

const index = withBlockQuery(({ fieldBody, classes }) => (
  <Block text={fieldBody} classes={classes} />
));
export { Block };
export default index;
