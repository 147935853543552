import React from 'react';
import injectStyles from 'react-jss';

const styles = ({ brakePoint: { mobile } }) => ({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    width: '100%',
    [mobile]: {
      justifyContent: 'space-around',
    },
  },
});

const PhotoDescContainer = injectStyles(styles)(({ classes, children }) => (
  <div className={classes.root}>{children}</div>
));

export default PhotoDescContainer;
