import React from 'react';
import injectSheet from 'react-jss';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const styles = theme => ({
  root: {
    display: 'block',
    border: 0,
    margin: 0,
    padding: 3,
    background: 'transparent',
    fontFamily: theme.font.family.arial,
    fontSize: theme.font.size.tiny,
    outline: 'none',
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'black',
    pointerEvents: 'auto',
    '&:hover': {
      textDecoration: 'underline',
      color: 'black',
    },
    '&:active': {
      textDecoration: 'none',
      color: 'black',
    },
    '&:visited': {
      textDecoration: 'none',
      color: 'black',
    },
  },
  active: {
    textDecoration: 'underline',
  },
});

const ExternalLinkButton = injectSheet(styles)(
  ({
    classes, children, to, active, newTab, className, theme, ...rest
  }) => (
    <a
      href={to}
      className={classnames(classes.root, active && classes.active, className)}
      {...{ ...(newTab && { target: '_blank', rel: 'noopener noreferrer' }) }}
      {...rest}
    >
      {children}
    </a>
  ),
);

const InternalLinkButton = injectSheet(styles)(
  ({
    classes, children, to = '', active, newTab, className, ...rest
  }) => (
    <Link to={to} className={classnames(classes.root, active && classes.active, className)} {...rest}>
      {children}
    </Link>
  ),
);

const HashLinkButton = injectSheet(styles)(({
  classes, children, to, active, newTab, ...rest
}) => (
  <HashLink smooth to={to} className={classnames(classes.root, active && classes.active)} {...rest}>
    {children}
  </HashLink>
));

export default ExternalLinkButton;

export { InternalLinkButton, ExternalLinkButton, HashLinkButton };
