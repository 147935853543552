import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';

const withLogotypesQuery = WrappedComponent => ({ blockId, ...props }) => {
  const FILE_QUERY = gql`
    query {
      logotypesView {
        results {
          id: nid
          fieldLogoLink {
            uri
          }
          fieldLogo {
            url
            derivative(style: PARTNERLOGO) {
              url
            }
          }
          fieldRelacja {
            entity {
              name
            }
          }
          fieldHoverText
          fieldName
        }
      }
    }
  `;
  return (
    <Query query={FILE_QUERY}>
      {({ data, loading }) => {
        const logotypes = {};
        _.get(data, 'logotypesView.results', []).forEach((element) => {
          const parsed = {
            id: element.id,
            hoverText: element.fieldHoverText,
            name: element.fieldName,
            imgUrl: element.fieldLogo.url,
            thumbnailUrl: element.fieldLogo.derivative.url,
            link: _.get(element, 'fieldLogoLink.uri', ''),
          };
          element.fieldRelacja.forEach(({ entity: { name } }) => {
            if (Array.isArray(logotypes[name])) {
              logotypes[name].push(parsed);
            } else {
              logotypes[name] = [parsed];
            }
          });
        });
        return <WrappedComponent {...props} loading={loading} logotypes={logotypes} />;
      }}
    </Query>
  );
};

export default withLogotypesQuery;
