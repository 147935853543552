import React from "react";
import injectStyles from "react-jss";
import AppHeader from "../AppHeader";
import Footer from "../Footer";
import ArrowIcon from "../styledElements/ArrowIcon";
import Typography from "../styledElements/Typography";

const styles = ({
  palette: { background, black },
  brakePoint: { mobile }
}) => ({
  root: {
    width: "100%",
    minHeight: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    backgroundColor: background,
    position: "relative"
  },
  contentContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "stretch",
    flex: "1 1 auto",
    padding: "60px 60px 0",
    [mobile]: {
      padding: "0"
    }
  },
  arrow: {
    height: "9px"
  },
  arrowRoot: {
    transform: "translateY(2px)",
    paddingRight: 10
  },
  mapNaviDesc: {
    display: "flex",
    paddingLeft: 30,
    paddingBottom: 10
  },
  mapDesc: {
    paddingBottom: 20,
    [mobile]: {
      padding: 20
    }
  },
  link: {
    textDecoration: "none",
    color: black
  }
});

const RootContainer = ({ children, classes }) => (
  <div id="contentContainer" className={classes.root}>
    <AppHeader />
    <div className={classes.contentContainer}>{children}</div>
    <Footer />
  </div>
);

function MapaVintage({ classes }) {
  return (
    <RootContainer classes={classes}>
      <Typography className={classes.mapDesc} size="small" font="minion">
        Mapa przedstawia sklepy, w których można zakupić używane ubrania, meble,
        przedmioty. Po kliknięciu na ikonę w lewym górnym rogu wysuwa się menu,
        w którym znajdują się poszczególne kategorie miejsc. Zapraszamy do
        korzystania :) Mapa jest stale aktualizowana i poszerzana - jeśli nie ma
        na niej jakiegoś wartego zaznaczenia miejsca zapraszamy do zgłoszenia go
        na adres: <a href="mailto:kontakt@fomkrakow.pl">kontakt@fomkrakow.pl</a>
        . Projekt zrealizowano przy udziale finansowym Gminy Miejskiej Kraków.
      </Typography>
      <Typography className={classes.mapNaviDesc} size="tiny" font="minion">
        <ArrowIcon
          classes={{ root: classes.arrowRoot, arrow: classes.arrow }}
        />
        Kliknij w ikonę w lewym górnym rogu, żeby otworzyć menu.
      </Typography>
      <iframe
        title="mapa-vintage"
        src="https://www.google.com/maps/d/embed?mid=1mtnlLGgeg8IlcXXG_v8WpRy5r9U1VmCL&hl=pl"
        width="100%"
        height="600px"
      />
    </RootContainer>
  );
}

export default injectStyles(styles)(MapaVintage);
