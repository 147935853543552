import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';

const withVolunteersQuery = WrappedComponent => ({ blockId, ...props }) => {
  const FILE_QUERY = gql`
    query {
      volunteerView {
        results {
          id: nid
          fieldFirstname
          fieldLastname
        }
      }
    }
  `;
  return (
    <Query query={FILE_QUERY}>
      {({ data, loading }) => {
        const volunteers = _.get(data, 'volunteerView.results', []).reduce((prev, curr) => {
          const cu = curr;
          const [firstNameLetter] = curr.fieldLastname;
          if (prev.length < 1 || prev[prev.length - 1].fieldLastname[0] !== firstNameLetter) {
            cu.firstOfName = firstNameLetter;
          }
          return [...prev, cu];
        }, []);
        return <WrappedComponent {...props} loading={loading} volunteers={volunteers} />;
      }}
    </Query>
  );
};

export default withVolunteersQuery;
