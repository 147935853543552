import classnames from 'classnames';
import React from 'react';
import injectStyles from 'react-jss';
import Typography from '../styledElements/Typography';

const styles = ({ palette: { red, green } }) => ({
  root: {
    fontSize: 24,
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '0 0 30px',
    marginRight: '5px',
  },
  red: {
    backgroundColor: red,
    boxShadow: `0 0 0 5px ${red}`,
  },
  green: {
    backgroundColor: green,
    boxShadow: `0 0 0 5px ${green}`,
  },
});
const FirstOfLetter = injectStyles(styles)(({ classes, letter, color }) => (
  <Typography
    style={{ transform: `translate(${color > 2 ? -10 : 0}px)` }}
    className={classnames(classes.root, letter && (color > 2 ? classes.red : classes.green))}
    font="questa"
    size="medium"
  >
    {letter}
  </Typography>
));

export default FirstOfLetter;
