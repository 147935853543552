import React from 'react';
import injectStyles from 'react-jss';
import classnames from 'classnames';
import Typography from '../../styledElements/Typography';
import sub2019 from '../../../assets/sub-2019-logo.png';
import withLogotypesQuery from './withLogotypesQuery';
import { updatedAtYear } from '../../../config.json';

const styles = ({ brakePoint: { tablet, mobile } }) => ({
  root: {
    width: 'auto',
    margin: 'auto 5%',
    [tablet]: {
      margin: '0',
    },
  },
  row: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
  },
  col1: {
    width: '35%',
    height: '250px',
    padding: '20px',
    minWidth: 300,
  },
  col1Thumbnail: {
    width: '35%',
    padding: '20px',
    minWidth: 300,
    [tablet]: {
      width: '100%',
      minWidth: 100,
    },
  },
  col2: {
    width: '60%',
    height: '250px',
    padding: '20px',
    minWidth: 400,
    display: 'flex',
    flexFlow: 'row',
    [mobile]: {
      width: '100%',
      minWidth: 100,
    },
  },
  col2Thumbnail: {
    width: '60%',
    padding: '20px',
    minWidth: 400,
    display: 'flex',
    flexFlow: 'row',
    [tablet]: {
      width: '100%',
      minWidth: 100,
    },
  },
  cell1: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '220px',
    [mobile]: {
      minWidth: 0,
      width: '100%',
    },
  },
  image: {
    maxWidth: '100%',
    margin: 5,
  },
  imgCaptionCont: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-around',
    minHeight: '100%',
  },
  imagesContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexFlow: 'row wrap',
  },
  thumbnail: {
    height: 50,
  },
  header: {
    marginBottom: '20px',
  },
  barUnder: {
    width: 260,
    [mobile]: {
      width: 220,
    },
  },
});

const ImgCaptionContainer = ({
  logos, classes, style, containerName, useThumbnail,
}) => {
  if (!logos) return null;
  const parsed = logos.map((logo) => {
    const image = useThumbnail ? logo.thumbnailUrl : logo.imgUrl;
    const imageComp = (
      <img
        key={logo.id}
        className={classnames(classes.image, useThumbnail && classes.thumbnail)}
        src={image}
        alt={logo.name}
      />
    );
    const LinkComp = ({ children }) => (
      <a key={logo.id} href={logo.link} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
    return logo.link ? <LinkComp key={`${logo.id}k`}>{imageComp}</LinkComp> : imageComp;
  });

  return (
    <div className={classes.imgCaptionCont} style={style}>
      <Typography className={classnames(useThumbnail && classes.header)} size="tiny" font="arial">
        {containerName}
      </Typography>
      <div className={classes.imagesContainer}>{parsed}</div>
    </div>
  );
};

const Logotypes = injectStyles(styles)(({ classes, logotypes }) => (
  <div className={classes.root}>
    <div className={classes.row}>
      <div className={classnames(classes.cell1, classes.col1)}>
        <Typography font="questa" size="large">
          {updatedAtYear}
        </Typography>
        <img className={classes.barUnder} src={sub2019} alt="bar under date" />
      </div>
      <div className={classes.col2}>
        <ImgCaptionContainer
          style={{ paddingRight: '10%', maxWidth: 280 }}
          logos={logotypes.Funded}
          classes={classes}
          containerName=""
        />
        <ImgCaptionContainer
          style={{ paddingRight: '10%', maxWidth: 280 }}
          logos={logotypes.Honors}
          classes={classes}
          containerName="Patron Honorowy"
        />
      </div>
    </div>
    <div className={classes.row}>
      <div className={classes.col1Thumbnail}>
        <ImgCaptionContainer
          useThumbnail
          logos={logotypes.Help}
          classes={classes}
          containerName="Partnerzy"
        />
      </div>
      <div className={classes.col2Thumbnail}>
        <ImgCaptionContainer
          useThumbnail
          logos={logotypes.Media}
          classes={classes}
          containerName="Media"
        />
      </div>
    </div>
  </div>
));

export default withLogotypesQuery(Logotypes);
