import React, { Component } from 'react';
import injectStyles from 'react-jss';
import classnames from 'classnames';
import Typography from '../styledElements/Typography';
import Slider from '../styledElements/Slider';
import Checkbox from '../styledElements/Checkbox';
import DistrictsDropdown from './DistrictsDropdown';
import minutesToHourString from '../../utils/minutesToHourString';
import DatesDropdown from './DatesDropdown';

const styles = ({
  palette: { black, red, green },
  font: { family, size },
  brakePoint: { mobile, tablet },
}) => ({
  root: {
    position: 'relative',
    width: '100%',
    border: `1px solid ${black}`,
    padding: '60px 30px 35px',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    [mobile]: {
      paddingTop: 50,
      flexWrap: 'nowrap',
      flexDirection: 'column',
    },
  },
  submitButton: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    WebkitAppearance: 'none',
    outline: 'none',
    textAlign: 'center',
    padding: '5px 10px',
    cursor: 'pointer',
    margin: '0',
    backgroundColor: 'transparent',
    color: black,
    borderLeft: `1px solid ${black}`,
    borderTop: `1px solid ${black}`,
    borderBottom: 'none',
    borderRight: 'none',
    '&:hover': {
      outline: 'none',
      backgroundColor: green,
    },
    '&:active': {
      color: black,
    },
  },
  clearButton: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    WebkitAppearance: 'none',
    outline: 'none',
    textAlign: 'center',
    padding: '5px 10px',
    cursor: 'pointer',
    margin: '0',
    backgroundColor: 'transparent',
    color: black,
    borderRight: `1px solid ${black}`,
    borderTop: `1px solid ${black}`,
    borderBottom: 'none',
    borderLeft: 'none',
    '&:hover': {
      outline: 'none',
      backgroundColor: red,
    },
    '&:active': {
      color: black,
    },
  },
  inlineContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  textInput: {
    fontFamily: family.arial,
    fontSize: size.tiny,
    fontStyle: 'italic',
    WebkitAppearance: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: `1px  solid ${black}`,
    textAlign: 'center',
    outline: 'none',
    minWidth: 260,
    [mobile]: {
      minWidth: 0,
      width: '100%',
    },
  },
  label: {
    flex: '0 1 16px',
  },
  content: {
    flex: '1 0 auto',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [mobile]: {
      width: '100%',
    },
  },
  field: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-start',
    margin: '20px 10px',
  },
  sliderContainer: {
    width: 200,
    [mobile]: {
      width: 'calc(100% - 100px)',
    },
  },
  sliderArrow: {
    '&:first-child': {
      marginRight: 9,
    },
    '&:last-child': {
      marginLeft: 9,
    },
  },
  title: {
    position: 'absolute',
    top: 18,
    left: '50%',
    transform: 'translate(-50%)',
  },
});

class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = { filters: props.filters };
  }

  componentWillReceiveProps(newProps) {
    this.setState(({ filters }) => ({
      filters: { ...filters, ...newProps.filters },
    }));
  }

  submitSearch = () => {
    const { saveFilters } = this.props;
    const { filters } = this.state;
    saveFilters(filters);
  };

  handleSliderChange = ([godzinaStart, godzinaKoniec]) => this.setState(({ filters }) => ({ filters: { ...filters, godzinaStart, godzinaKoniec } }));

  handleChange = ({ target: { value, name } }) => this.setState(({ filters }) => ({ filters: { ...filters, [name]: value } }));

  handleTextChange = ({ target: { value } }) => this.setState(({ filters }) => ({ filters: { ...filters, opis: value } }));

  render = () => {
    const { classes, resetFilters } = this.props;
    const {
      filters: {
        godzinaStart, godzinaKoniec, lokalizacja, wydarzenie, opis, dzielnica, daty,
      },
    } = this.state;
    return (
      <div className={classes.root}>
        <Typography className={classes.title} font="minion" size="medium">
            Wybierz
          </Typography>
        <div className={classes.field}>
          <Typography className={classes.label} font="arial" size="tiny">
            dzień
          </Typography>
          <DatesDropdown
            className={classes.content}
            value={daty}
            handleChange={this.handleChange}
            name="daty"
          />
        </div>
        <div className={classes.field}>
          <Typography className={classes.label} font="arial" size="tiny">
            godzinę rozpoczęcia
          </Typography>
          <div className={classnames(classes.inlineContainer, classes.content)}>
            <Typography
              className={classnames(classes.inlineContainer, classes.sliderArrow)}
              font="arial"
              size="tiny"
            >
              {`${minutesToHourString(godzinaStart)} < `}
            </Typography>
            <div className={classes.sliderContainer}>
              <Slider
                value={[Number(godzinaStart), Number(godzinaKoniec)]}
                handleChange={this.handleSliderChange}
              />
            </div>
            <Typography
              className={classnames(classes.inlineContainer, classes.sliderArrow)}
              font="arial"
              size="tiny"
            >
              {` > ${minutesToHourString(godzinaKoniec)}`}
            </Typography>
          </div>
        </div>

        <div className={classes.field}>
          <Typography className={classes.label} font="arial" size="tiny">
            dzielnice
          </Typography>
          <DistrictsDropdown
            name="dzielnica"
            handleChange={this.handleChange}
            className={classes.content}
            value={dzielnica}
          />
        </div>

        <div className={classes.field}>
          <Typography className={classes.label} font="arial" size="tiny">
            {' '}
          </Typography>
          <div>
            <Checkbox name="lokalizacja" checked={lokalizacja} onChange={this.handleChange} />
            <Checkbox name="wydarzenie" checked={wydarzenie} onChange={this.handleChange} />
          </div>
        </div>

        <div style={{ justifyContent: 'center' }} className={classes.field}>
          <input
            value={opis}
            onChange={this.handleTextChange}
            className={classes.textInput}
            placeholder="wpisz dowolną frazę n.p. mieszkanie"
          />
        </div>
        <button onClick={this.submitSearch} className={classes.submitButton} type="button">
          <Typography font="arial" size="tiny">
            enter
          </Typography>
        </button>
        <button onClick={resetFilters} className={classes.clearButton} type="button">
          <Typography font="arial" size="tiny">
            wyczyść
          </Typography>
        </button>
      </div>
    );
  };
}

export default injectStyles(styles)(Filters);
