import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';

const withDistrictsQuery = WrappedComponent => ({ filters, ...props }) => {
  const DISTRICTS_QUERY = gql`
    query DISTRICTS {
      districtsView {
        results {
          name
          tid
        }
      }
    }
  `;
  return (
    <Query query={DISTRICTS_QUERY}>
      {({ data }) => {
        const districts = _.get(data, 'districtsView.results', []).map(district => ({
          value: district.name,
          id: `${district.tid}`,
        }));

        return <WrappedComponent districts={districts} {...props} />;
      }}
    </Query>
  );
};

export default withDistrictsQuery;
