import React, { Component } from 'react';
import ReactGa from 'react-ga';
import { withClient } from '../../ClientWidth';
import Breadcrumbs from '../Breadcrumbs';
import TitleContaier from '../TitleContainer';
import ZespolContainer from '../ZespolContainer';
import CenterContainer from '../CenterContainer';
import Typography from '../styledElements/Typography';
import Divider from '../styledElements/Divider';
import Logo from '../Logo';
import DaneStowarzyszenia from './DaneStowarzyszenia';
import TrimmedContainer from '../styledElements/TrimmedContainer';
import SocialLinks from '../SocialLinks';


class Kontakt extends Component {
  componentDidMount() {
    ReactGa.pageview('/kontakt');
  }

  render = () => {
    const {
      client: { mobile },
    } = this.props;
    return (
      <div>
        <Breadcrumbs fullWidth heading="Kontakt" />
        <CenterContainer>
          <TrimmedContainer>
            <TitleContaier withArrow blockId="summary-kontakt" />
            <SocialLinks
              style={{
                alignSelf: 'flex-end',
                marginRight: '20px',
              }}
            />
            <Divider hidden height={80} />
            <ZespolContainer />
            <Divider hidden height={160} />
          </TrimmedContainer>
          <Logo color="dark" />
          <Typography style={{ textAlign: 'center' }} font="questa" size="medium">
            {'Festiwal Otwarte Mieszkania '}
            {!mobile && <br />}
            {'Kraków'}
          </Typography>
          <Divider hidden height={40} />
          <Divider />
          <Divider hidden height={5} />
          <DaneStowarzyszenia />
        </CenterContainer>
      </div>
    );
  };
}

export default withClient(Kontakt);
