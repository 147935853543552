import React from 'react';
import injectStyles from 'react-jss';
import _ from 'lodash';
import withDataQuery from '../Kontakt/DaneStowarzyszenia/withDataQuery';
import { Block } from '../BlockContainer';
import escapeNewLines from '../../utils/escapeNewLines';
import historia1 from '../../assets/images/historia1.png';
import historia2 from '../../assets/images/historia2.png';
import historia3 from '../../assets/images/historia3.png';
import historia4 from '../../assets/images/historia4.png';
import historia5 from '../../assets/images/historia5.png';
import ImageCaptionContainer from '../ImageCaptionContainer';

const styles = ({ brakePoint: { mobile } }) => ({
  root: {},
  blockOverwrite: {
    maxWidth: 500,
    margin: '0 auto',
    paddingRight: 30,
    [mobile]: {
      paddingRight: 0,
      textAlign: 'justify',
    },
  },
  inlineContianer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    [mobile]: {
      flexFlow: 'column',
    },
  },
  imageContainer: {
    marginRight: 30,
    transform: 'translateY(60px)',
    height: 250,
    [mobile]: {
      height: 190,
      transform: 'none',
      margin: '0px auto 30px auto',
    },
  },
  imagesContiner: {
    marginTop: 30,
    width: 700,
    display: 'block',
    height: 300,
    position: 'relative',
    [mobile]: {
      height: 200,
      width: 'auto',
    },
  },
  imagesContiner2: {
    marginTop: 30,
    width: 570,
    display: 'block',
    height: 450,
    position: 'relative',
    [mobile]: {
      marginTop: 20,
      height: 200,
      width: 'auto',
    },
  },
  image1: {
    position: 'absolute',
    top: 0,
    left: 90,
    height: 130,
    [mobile]: {
      display: 'none',
    },
  },
  image2: {
    right: 0,
    position: 'absolute',
    top: -10,
    height: 250,
    [mobile]: {
      top: 0,
      height: 150,
      position: 'relative',
    },
  },
  image3: {
    position: 'absolute',
    top: 90,
    left: 0,
    height: 240,
    [mobile]: {
      top: 0,
      height: 150,
      position: 'relative',
    },
  },
  image4: {
    right: 0,
    height: 175,
    position: 'absolute',
    top: 0,
    [mobile]: {
      display: 'none',
    },
  },
});

const Historia = withDataQuery(
  injectStyles(styles)(({ classes, cells }) => {
    const block0 = escapeNewLines(_.get(cells, '[0].value', ''));
    const block1 = escapeNewLines(_.get(cells, '[1].value', ''));
    return (
      <>
        <div style={{ zIndex: 1 }} className={classes.inlineContianer}>
          <ImageCaptionContainer
            height="100%"
            className={classes.imageContainer}
            author="Fot. Paula Pietruszka"
            src={historia1}
          />
          <Block classes={{ root: classes.blockOverwrite }} text={block0} />
        </div>
        <div className={classes.imagesContiner}>
          <ImageCaptionContainer
            bottom
            className={classes.image1}
            height="100%"
            author="Fot. Michał Zaborowski / Niezależny Klub Fotograficzny"
            src={historia2}
          />
          <ImageCaptionContainer
            height="100%"
            className={classes.image2}
            author="Fot. Paula Pietruszka"
            src={historia3}
          />
        </div>
        <Block classes={{ root: classes.blockOverwrite }} text={block1} />
        <div className={classes.imagesContiner2}>
          <ImageCaptionContainer
            right
            className={classes.image4}
            height="100%"
            author="Fot. Paula Pietruszka"
            src={historia5}
          />
          <ImageCaptionContainer
            height="100%"
            className={classes.image3}
            author="Fot. Paula Pietruszka"
            src={historia4}
          />
        </div>
      </>
    );
  }),
)({ blockId: 'opis_historia' });

export default () => Historia;
