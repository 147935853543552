import React from 'react';
import injectStyles from 'react-jss';
import _ from 'lodash';
import Typography from '../styledElements/Typography';
import withFiles from '../withFiles';
import { ExternalLinkButton } from '../styledElements/LinkButton';
import documentIcon from '../../assets/documentIcon.png';
import logoBlack from '../../assets/images/logo-black.png';
import { updatedAtYear } from '../../config.json'

const styles = ({ palette: { black, red, green }, transition: { duration, effect } }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
    margin: '60px auto',
  },
  inner: {
    borderBottom: `1px solid ${black}`,
    padding: '15px 90px',
    position: 'relative',
    lineHeight: 1,
  },
  button: {
    width: 110,
    height: 110,
    borderRadius: '50%',
    backgroundColor: red,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: `background-color ${duration} ${effect}`,
    willChange: 'background-color',
    '&:hover': {
      backgroundColor: green,
    },
  },
  buttonContainer: {
    position: 'absolute',
    top: -13,
    right: 30,
  },
  linkButton: {
    marginTop: 30,
  },
  logoContainer: {
    position: 'absolute',
    top: 34,
    left: 44,
  },
});

const DateContainer = injectStyles(styles)(({ classes }) => {
  const ProgramLinkWithQuery = withFiles(({ files }) => (
    <div className={classes.root}>
      <div className={classes.inner}>
        <div className={classes.logoContainer}>
          <img style={{ height: 36 }} src={logoBlack} alt="program pdf" />
        </div>
        <div className={classes.buttonContainer}>
          <ExternalLinkButton
            className={classes.button}
            newTab
            to={_.get(files, '[0].fieldPlik.entity.url', '')}
          >
            <img style={{ height: '50%' }} src={documentIcon} alt="document" />
          </ExternalLinkButton>
        </div>
        <Typography font="questa" size="giant">
          {updatedAtYear}
        </Typography>
      </div>
      <ExternalLinkButton
        newTab
        className={classes.linkButton}
        download
        to={_.get(files, '[0].fieldPlik.entity.url', '')}
      >
        {'<pobierz program w pdf>'}
      </ExternalLinkButton>
    </div>
  ));
  return <ProgramLinkWithQuery blockId="pdf-program" />;
});

export default DateContainer;
