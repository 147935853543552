import React from 'react';
import injectStyles from 'react-jss';
import Typography from './Typography';
import ArrowIcon from './ArrowIcon';

const styles = () => ({
  root: {
    minWidth: 200,
    maxWidth: 310,
    position: 'relative',
    paddingLeft: 15,
    paddingTop: 15,
  },
  arrowRoot: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

const BlockWithArrow = injectStyles(styles)(({ classes, header, text }) => (
  <div className={classes.root}>
    <Typography font="minion" size="medium">
      {header}
    </Typography>
    <Typography font="minion" size="tiny">
      {text}
    </Typography>
    <ArrowIcon classes={{ root: classes.arrowRoot }} />
  </div>
));

export default BlockWithArrow;
