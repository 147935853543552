import React from 'react';
import injectStyles from 'react-jss';
import AppHeader from './AppHeader';
import Footer from './Footer';

const styles = ({ palette: { background }, brakePoint: { mobile } }) => ({
  root: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    backgroundColor: background,
    position: 'relative',
  },
  contentContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'stretch',
    flex: '1 1 auto',
    padding: '30px 60px 0 60px',
    [mobile]: {
      padding: '30px 40px 0 40px',
    },
  },
});

const RootContainer = injectStyles(styles)(({ children, classes }) => (
  <div id="contentContainer" className={classes.root}>
    <AppHeader />
    <div className={classes.contentContainer}>{children}</div>
    <Footer />
  </div>
));

export default RootContainer;
