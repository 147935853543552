export default ({ font: { size, family }, palette }) => ({
  root: {},
  hpContainers: {
    width: '100%',
    height: '100%',
    margin: '0',
    padding: '0',
    backgroundColor: palette.red,
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none',
    overflow: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  body: {
    position: 'fixed',
    overflow: 'hidden',
    overscrollBehaviorY: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  canvas: {
    position: 'absolute',
    top: '0',
    pointerEvents: 'none',
  },
  scrollContianer: {
    position: 'absolute',
    overflow: 'auto',
    top: '0px',
    left: '0',
    width: '100vw',
    height: '100vh',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});
