import React, { Component } from 'react';
import ReactGa from 'react-ga';
import CenterContainer from '../CenterContainer';
import Breadcrumbs from '../Breadcrumbs';
import Idea from './Idea';
import Historia from './Historia';
import Divider from '../styledElements/Divider';
import Logotypes from './Logotypes';

class index extends Component {
  componentDidMount() {
    ReactGa.pageview('/festiwal');
  }

  render = () => (
    <>
      <div id="Idea">
        <Breadcrumbs
          primary
          heading="Festiwal"
          sections={[{ val: 'Idea', selected: true }, { val: 'Historia' }]}
        />
        <CenterContainer>
          <Idea />
        </CenterContainer>
      </div>
      <div id="Historia">
        <Breadcrumbs
          heading="Festiwal"
          sections={[{ val: 'Idea' }, { val: 'Historia', selected: true }]}
        />
        <CenterContainer>
          <Historia />
        </CenterContainer>
      </div>
      <Divider height={1} />
      <Divider hidden height={20} />
      <Logotypes />
    </>
  );
}

export default index;
