import _ from 'lodash';
import React from 'react';
import { animated as anim, interpolate } from 'react-spring/three';
import { useThree } from 'react-three-fiber';
import * as THREE from 'three/src/Three';
import data from './data';
import ImageContainer from './ImageContainer';
import Text from './Text';

const MOUSE_FOLLOW_FACTOR = 20.0 / 1000;
const ROTATION_SPEED = 180;

function Scene({ left, mouse, client }) {
  const { size } = useThree();
  const sceneW = size.width;
  const position = left.interpolate({
    range: _.flatten(
      [0, 1, 2, 3, 4, 5].map(i => [
        i * sceneW,
        i * sceneW + sceneW * 0.4,
        i * sceneW + sceneW * 0.4 + sceneW / 2.5,
      ]),
    ),
    output: _.flatten(
      Array.from({ length: 6 }).map((_a, i) => (i % 2 === 0 ? [1, 1, -1] : [-1, -1, 1])),
    ),
  });
  const rotationFollow = interpolate([left, mouse], (l, m) => [
    THREE.Math.degToRad(m[1] * MOUSE_FOLLOW_FACTOR),
    THREE.Math.degToRad(m[0] * MOUSE_FOLLOW_FACTOR + (l / sceneW) * ROTATION_SPEED),
    THREE.Math.degToRad(m[2] * MOUSE_FOLLOW_FACTOR),
  ]);
  const shadeIn = left.interpolate(l => (l > sceneW ? 1 : 0));
  const shadeOut = left.interpolate(l => (l < sceneW ? 1 : 0));
  const shadeFirst = left.interpolate(l => (l < sceneW * 0.7 ? 1 : 0));
  const shadeSecond = left.interpolate(l => ((l > sceneW * 0.7 && l < sceneW * 1.7) ? 1 : 0));
  const shadeThird = left.interpolate(l => (l > sceneW * 1.7 ? 1 : 0));
  const source = client.mobile ? data.mobile : data.desktop;
  return (
    <anim.mesh rotation={rotationFollow}>
      <ImageContainer {...source.background[0]} side={THREE.DoubleSide} position={position} />
      <ImageContainer {...source.background[1]} side={THREE.DoubleSide} position={position} />
      <ImageContainer {...source.left[0]} opacity={shadeFirst} side={THREE.FrontSide} position={position} />
      <ImageContainer {...source.left[1]} opacity={shadeSecond} side={THREE.BackSide} position={position} />
      <ImageContainer {...source.left[2]} opacity={shadeThird} side={THREE.FrontSide} position={position} />
      <ImageContainer {...source.right[0]} opacity={shadeFirst} side={THREE.FrontSide} position={position} />
      <ImageContainer {...source.right[1]} opacity={shadeSecond} side={THREE.BackSide} position={position} />
      <ImageContainer {...source.right[2]} opacity={shadeThird} side={THREE.FrontSide} position={position} />

      <ImageContainer
        {...source.center[0]}
        opacity={shadeOut}
        side={THREE.FrontSide}
        position={[source.center[0].x, source.center[0].y, source.center[0].z]}
      />
      <ImageContainer
        {...source.center[1]}
        opacity={shadeIn}
        side={THREE.FrontSide}
        position={[source.center[1].x, source.center[1].y, source.center[1].z]}
      />
      <Text
        side={THREE.BackSide}
        {...source.text[0]}
        position={[source.text[0].x, source.text[0].y, source.text[0].z]}
      />
    </anim.mesh>
  );
}

export default Scene;
