import React from 'react';
import injectStyles from 'react-jss';
import _ from 'lodash';
import Typography from '../styledElements/Typography';
import withFiles from '../withFiles';
import { ExternalLinkButton } from '../styledElements/LinkButton';
import gdprIcon from '../../assets/gdprIcon.png';
import mapIcon from '../../assets/mapIcon.png';
import { updatedAtYear } from '../../config.json'

const styles = ({
  palette: { red, green },
  transition: { duration, effect },
  font: {
    size: { tiny },
  },
  brakePoint: { mobile },
}) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row',
    margin: '60px auto 20px auto',
    position: 'relative',
    lineHeight: 1,
    width: 480,
    [mobile]: {
      width: 'auto',
      margin: 'auto',
    },
  },
  button: {
    width: 110,
    height: 110,
    borderRadius: '50%',
    backgroundColor: red,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: `background-color ${duration} ${effect}`,
    willChange: 'background-color',
    '&:hover': {
      backgroundColor: green,
    },
  },
  buttonContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '-75px 0 0 -50px',
    [mobile]: {
      margin: 0,
      width: '100%',
    },
  },
  linkButton: {
    marginTop: 30,
  },
  logoContainer: {
    position: 'absolute',
    top: 34,
    left: 44,
  },
  linkContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 5,
    width: 110,
    [mobile]: {
      flexFlow: 'column-reverse nowrap',
      margin: '0 10px',
    },
  },
  fontSuperTiny: {
    fontSize: '10px',
    letterSpacing: -0.3,
    whiteSpace: 'nowrap',
  },
  year: {
    [mobile]: {
      display: 'none',
    },
  },
});

const DateContainer = injectStyles(styles)(({ classes }) => {
  const ProgramLinkWithQuery = withFiles(({ files }) => (
    <div className={classes.root}>
      <Typography className={classes.year} font="questa" size="giant">
        {updatedAtYear}
      </Typography>
      <div className={classes.buttonContainer}>
        <div className={classes.linkContainer}>
          <ExternalLinkButton
            className={classes.fontSuperTiny}
            newTab
            to={_.get(
              files && files.find(file => file.fieldBlockId === 'pdf-program'),
              'fieldPlik.entity.url',
            )}
          >
            {'< pobierz program w pdf >'}
          </ExternalLinkButton>
          <ExternalLinkButton
            className={classes.button}
            newTab
            to={_.get(
              files && files.find(file => file.fieldBlockId === 'pdf-program'),
              'fieldPlik.entity.url',
            )}
          >
            <img style={{ height: '50%' }} src={mapIcon} alt="program pdf" />
          </ExternalLinkButton>
        </div>
        <div className={classes.linkContainer}>
          <ExternalLinkButton
            className={classes.fontSuperTiny}
            newTab
            to={_.get(
              files && files.find(file => file.fieldBlockId === 'pdf-regulamin-festiwalu'),
              'fieldPlik.entity.url',
            )}
          >
            {'< pobierz regulamin w pdf >'}
          </ExternalLinkButton>
          <ExternalLinkButton
            className={classes.button}
            newTab
            to={_.get(
              files && files.find(file => file.fieldBlockId === 'pdf-regulamin-festiwalu'),
              'fieldPlik.entity.url',
            )}
          >
            <img style={{ height: '50%' }} src={gdprIcon} alt="regulamin pdf" />
          </ExternalLinkButton>
        </div>
      </div>
    </div>
  ));
  return <ProgramLinkWithQuery blockId={['pdf-program', 'pdf-regulamin-festiwalu']} />;
});

export default DateContainer;
