import React from 'react';
import injectStyles from 'react-jss';
import Breadcrumbs from '../Breadcrumbs';
import CenterContainer from '../CenterContainer';
import TrimmedContainer from '../styledElements/TrimmedContainer';
import Typography from '../styledElements/Typography';
import ZespolContainer from '../ZespolContainer';
import Divider from '../styledElements/Divider';

const styles = ({ brakePoint: { mobile } }) => ({
  root: {},
  inline: {
    display: 'flex',
    flexFlow: 'row',
    width: '100%',
    [mobile]: {
      flexFlow: 'column',
    },
  },
  rowContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    marginTop: 20,
    [mobile]: {
      flexFlow: 'column nowrap',
    },
  },
  cell2: {
    flex: '0 1 48%',
    marginBottom: 10,
    minWidth: 120,
    [mobile]: {
      flex: '0 1 auto',
    },
  },
  column: {
    marginRight: 60,
  },
});

const Zespol = ({ classes }) => (
  <div id="Zespół">
    <Breadcrumbs
      heading="O nas"
      sections={[
        { val: 'Stowarzyszenie' },
        { val: 'Zespół', selected: true },
        { val: 'Wolontariusze' },
      ]}
    />
    <CenterContainer>
      <TrimmedContainer>
        <ZespolContainer />
        <Divider hidden height={50} />
        <div className={classes.inline}>
          <div className={classes.column}>
            <Typography size="medium" font="questa">
              Strona
            </Typography>
            <div className={classes.rowContainer}>
              {['Dominik Gonicarz', 'Rafał Kucharczuk'].map(name => (
                <Typography key={name} className={classes.cell2} size="tiny" font="arial">
                  {name}
                </Typography>
              ))}
            </div>
          </div>
          <div className={classes.column}>
            <Typography size="medium" font="questa">
              Fotografia
            </Typography>
            <div className={classes.rowContainer}>
              {[
                'Paula Pietruszka',
                'Jan Laskowski',
              ].map(name => (
                <Typography key={name} className={classes.cell2} size="tiny" font="arial">
                  {name}
                </Typography>
              ))}
            </div>
          </div>
        </div>
      </TrimmedContainer>
    </CenterContainer>
  </div>
);

export default injectStyles(styles)(Zespol);
