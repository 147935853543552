import React, { useMemo } from 'react';
// A React animation lib, see: https://github.com/react-spring/react-spring
import { animated as anim } from 'react-spring/three';
import * as THREE from 'three/src/Three';
import { getLines } from '../utils';

function Text({
  text, items, color = 'black', fontSize = 44, side, scale, ratio, ...props
}) {
  const canvas = useMemo(() => {
    const fontSizeSmall = 30;
    const CANVAS_SIZE = 1024;
    const margin = CANVAS_SIZE * 0.1;
    const canvElem = document.createElement('canvas');
    canvElem.width = CANVAS_SIZE;
    canvElem.height = CANVAS_SIZE;

    const ctx = canvElem.getContext('2d');
    ctx.setTransform(-1, 0, 0, 1, canvElem.width, 0);
    ctx.scale(1 / ratio, 1);
    ctx.beginPath();
    ctx.rect(0, 0, CANVAS_SIZE * ratio, CANVAS_SIZE);
    ctx.fillStyle = 'rgb(157, 191, 158)';
    ctx.fill();
    ctx.font = `${fontSize}px Crimson-Roman, arial, sans-serif`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'top';
    ctx.fillStyle = color;
    const lines = getLines(ctx, text, CANVAS_SIZE * ratio - 2 * margin);
    lines.forEach((line, i) => {
      ctx.fillText(line, (CANVAS_SIZE * ratio) / 2, (i + 1) * (fontSize + 8));
    });
    ctx.beginPath();
    ctx.moveTo(margin, (lines.length + 1) * (fontSize + 8) + 50);
    ctx.lineTo(CANVAS_SIZE * ratio - margin, (lines.length + 1) * (fontSize + 8) + 50);
    ctx.lineWidth = 2;
    ctx.strokeStyle = '#000';
    ctx.stroke();
    ctx.textAlign = 'left';
    ctx.font = `${fontSizeSmall}px Crimson-Roman, arial, sans-serif`;
    items.forEach((item, i) => {
      item
        .split('\n')
        .forEach((textLine, j) => ctx.fillText(
          textLine,
          margin,
          (lines.length + 1) * (fontSize + 8) + 80 + (j + i * 3) * (fontSizeSmall + 8),
        ));
    });

    ctx.beginPath();
    ctx.moveTo(margin / 2, margin / 2 - 8);
    ctx.lineTo(margin / 2, margin / 2 + 7);
    ctx.lineWidth = 2;
    ctx.stroke();
    ctx.lineTo(margin / 2 - 10, margin / 2 + 7);
    ctx.lineTo(margin / 2, margin / 2 + 18);
    ctx.lineTo(margin / 2 + 10, margin / 2 + 7);
    ctx.lineTo(margin / 2, margin / 2 + 7);
    ctx.closePath();
    ctx.fillStyle = '#000';
    ctx.fill();

    return canvElem;
  }, [text, items, color, fontSize, ratio]);

  const onUpdate = (s) => {
    const ss = s;
    ss.needsUpdate = true;
    return ss;
  };

  return (
    <anim.mesh {...props}>
      <planeBufferGeometry attach="geometry" args={[scale * ratio, scale]} />
      <anim.meshStandardMaterial side={side} attach="material" transparent>
        <canvasTexture
          minFilter={THREE.LinearFilter}
          attach="map"
          image={canvas}
          onUpdate={onUpdate}
        />
      </anim.meshStandardMaterial>
    </anim.mesh>
  );
}

export default Text;
