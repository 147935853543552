import React from 'react';
import injectStyles from 'react-jss';
import arrowIcon from '../../assets/arrow-down.png';

const styles = () => ({
  root: {},
  arrow: {
    height: 11,
  },
});

const ArrowIcon = injectStyles(styles)(({ classes, style = {} }) => (
  <div className={classes.root}>
    <img className={classes.arrow} style={style.arrow} src={arrowIcon} alt="arrow down icon" />
  </div>
));

export default ArrowIcon;
