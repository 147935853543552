import React from 'react';
import injectStyles from 'react-jss';
import withBlockQuery from './withBlockQuery';
import Typography from '../styledElements/Typography';
import ArrowIcon from '../styledElements/ArrowIcon';

const styles = ({ brakePoint: { mobile } }) => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  text: {
    margin: '10px 0 10px auto',
    position: 'relative',
    width: 360,
    [mobile]: {
      width: '100%',
      maxWidth: 390,
    },
  },
  arrow: {
    position: 'absolute',
    top: -20,
    left: -20,
  },
});

const index = injectStyles(styles)(({ classes, fieldTextTrimmed, withArrow }) => (
  <div className={classes.root}>
    <Typography className={classes.text} size="medium" font="minion">
      {withArrow && <ArrowIcon classes={{ root: classes.arrow }} />}
      {fieldTextTrimmed}
    </Typography>
  </div>
));

const TitleContaier = withBlockQuery(index);

export { index as TitleContaierNoQuery };
export default TitleContaier;
