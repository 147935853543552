import React, { useState } from 'react';
import Section from './Section';
import withFaqQuery from './withFaqQuery';

const Faq = withFaqQuery(({ faqs }) => {
  const [selectedIndex, setIndex] = useState(null);
  const onClick = i => () => setIndex(selectedIndex === i ? undefined : i);
  return faqs.map(([cathegory, elements], i) => (
    <Section
      collapsed={selectedIndex !== i}
      onClick={onClick(i)}
      key={i}
      name={cathegory}
      faqs={elements}
    />
  ));
});

export default Faq;
