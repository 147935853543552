import React from 'react';
import _ from 'lodash';
import injectStyles from 'react-jss';
import withFile from './withFiles';
import { ExternalLinkButton } from './styledElements/LinkButton';

const styles = () => ({
  spacedLink: {
    letterSpacing: '5px',
  },
});

const ProgramLink = injectStyles(styles)(({ classes, customText }) => {
  const ProgramLinkWithQuery = withFile(({ files }) => (
    <ExternalLinkButton
      newTab
      className={classes.spacedLink}
      to={_.get(files, '[0].fieldPlik.entity.url', '')}
    >
      {customText || '<program>'}
    </ExternalLinkButton>
  ));
  return <ProgramLinkWithQuery blockId="pdf-program" />;
});

export default ProgramLink;
