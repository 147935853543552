import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';

const withBlockQuery = WrappedComponent => ({ blockId, ...props }) => {
  const BLOCK_BY_ID = gql`
    query {
      blockContentQuery(filter: {conditions: {field: "field_block_id", value: "${blockId}", operator: EQUAL}}) {
        entities {
          ... on BlockContentShortTextBlock {
            id
            fieldTextTrimmed
          }
        }
      }
    }
  `;
  return (
    <Query query={BLOCK_BY_ID}>
      {({ data, loading }) => {
        const fieldTextTrimmed = _.get(data, 'blockContentQuery.entities[0].fieldTextTrimmed', '');
        return (
          <WrappedComponent
            {...props}
            loading={loading}
            fieldTextTrimmed={fieldTextTrimmed}
          />
        );
      }}
    </Query>
  );
};

export default withBlockQuery;
