import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Query } from 'react-apollo';

const EVENT_DATES_QUERY = gql`
  query DatyWydarzen {
    datyWydarzenGraphql1View {
      results {
        uuid
        fieldDateEnd {
          value
        }
        fieldDateStart {
          value
        }
      }
    }
  }
`;

const dateToObj = date => ({
  id: date.format('YYYY-MM-DD'),
  value: date.format('DD.MM'),
});

const withDatesQuery = WrappedComponent => ({ filters, ...props }) => (
  <Query query={EVENT_DATES_QUERY}>
    {({ data }) => {
      const parsedDates = _.flatten(
        _.get(data, 'datyWydarzenGraphql1View.results', []).map(
          ({ fieldDateEnd, fieldDateStart }) => [fieldDateStart.value, fieldDateEnd.value],
        ),
      ).sort((a, b) => a.localeCompare(b));

      const dates = [];
      if (parsedDates && parsedDates.length > 0) {
        const start = moment(parsedDates[0], 'YYYY-MM-DD').startOf('day');
        const end = moment(parsedDates[parsedDates.length - 1], 'YYYY-MM-DD').startOf('day');
        dates.push(dateToObj(start));

        while (start.add(1, 'days').diff(end) <= 0) {
          dates.push(dateToObj(start.clone()));
        }
      }

      return (
        <WrappedComponent
          {...props}
          filters={filters}
          dateOptions={[{ id: '', value: '- Wszystkie -' }].concat(dates)}
        />
      );
    }}
  </Query>
);

export default withDatesQuery;
