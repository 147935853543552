import React, { useCallback, useMemo, useState } from 'react';
import { animated as anim, useSpring } from 'react-spring/three';
import * as THREE from 'three/src/Three';

/** This component loads an image and projects it onto a plane */
function ImageContainer({
  url, scale, ratio, side, opacity, position, x, y, z,
}) {
  const texture = useMemo(() => new THREE.TextureLoader().load(url), [url]);
  const [hovered, setHover] = useState(false);
  const hover = useCallback(() => setHover(true), []);
  const unhover = useCallback(() => setHover(false), []);
  // const { factor } = useSpring({ factor: hovered ? 1.1 : 1 });
  const { factor } = useSpring({ factor: hovered ? 1 : 1 });
  return (
    <anim.mesh
      position={
        Array.isArray(position) ? position : position.interpolate(mult => [mult * x, y, mult * z])
      }
      onHover={hover}
      onUnhover={unhover}
      scale={factor.interpolate(f => [f, f, 1])}
    >
      <planeBufferGeometry attach="geometry" args={[scale * ratio, scale]} />
      <anim.meshStandardMaterial
        // roughness={0.2}
        // metalness={0.2}
        attach="material"
        side={side}
        opacity={opacity}
        transparent
        alphaTest={0.5}
      >
        <primitive attach="map" object={texture} />
      </anim.meshStandardMaterial>
    </anim.mesh>
  );
}

export default ImageContainer;
