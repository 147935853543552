import React, { Component } from 'react';
import ReactGa from 'react-ga';
import injectStyles from 'react-jss';
import wolontariat1 from '../../assets/wolontariat1.png';
import diamondIcon from '../../assets/diamond.png';
import marta from '../../assets/resp_volo.jpg';
import Divider from '../styledElements/Divider';
import TitleContaier from '../TitleContainer';
import Typography from '../styledElements/Typography';
import CenterContainer from '../CenterContainer';
import Breadcrumbs from '../Breadcrumbs';
import ArrowIcon from '../styledElements/ArrowIcon';
import { ExternalLinkButton } from '../styledElements/LinkButton';

const styles = ({
  palette: { red },
  font: {
    family: { minion },
  },
  brakePoint: { mobile, tablet },
}) => ({
  inlineContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    [mobile]: {
      flexFlow: 'row wrap',
    },
  },
  columnContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    [mobile]: {
      margin: 'auto',
    },
  },
  imageContianer: {
    width: 300,
    marginRight: 60,
    [tablet]: {
      width: 250,
    },
    [mobile]: {
      width: '90%',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
  image: {
    width: '100%',
  },
  playButton: {
    backgroundColor: red,
    width: 120,
    height: 120,
    borderRadius: '50%',
    backgroundImage: `url(${diamondIcon})`,
    backgroundSize: 70,
    backgroundPosition: 'center 42px',
    backgroundRepeat: 'no-repeat',
    marginBottom: 20,
  },
  linkContainer: {
    width: '100%',
    textAlign: 'right',
    marginTop: -15,
  },
  centerContent: {
    width: '100%',
    textAlign: 'center',
  },
  fontLarger: {
    fontFamily: minion,
    fontSize: '50px',
  },
  arrowLeft: {
    transform: 'rotateZ(-90deg)',
  },
  arrowRight: {
    transform: 'rotateZ(90deg)',
  },
  paddedImg: {
    margin: '10px 40px',
  },
  textSmallContainer: {
    maxWidth: 320,
    margin: '0 10px 30px 10px',
    [mobile]: {
      margin: '20px auto',
      maxWidth: 500,
    },
  },
  centerMobile: {
    [mobile]: {
      maxWidth: 390,
      margin: '70px auto',
    },
  },
});

class WesprzyjNas extends Component {
  componentDidMount() {
    ReactGa.pageview('/wolontariat');
  }

  render = () => {
    const { classes } = this.props;
    return (
      <>
        <Breadcrumbs primary heading="Wolontariat" sections={[{ val: 'kontakt' }]} />
        <CenterContainer>
          <div className={classes.inlineContainer}>
            <div className={classes.imageContianer}>
              <img className={classes.image} src={wolontariat1} alt="diamond" />
            </div>
            <div className={classes.centerMobile}>
              <TitleContaier blockId="opis-wolontariat" />
              <Divider hidden height={30} />
              <div className={classes.columnContainer}>
                <div className={classes.playButton} />
                <Typography className={classes.centerContent} font="arial" size="tiny">
                  {'< put a ring on it >'}
                </Typography>
              </div>
            </div>
          </div>
          <Divider hidden height={40} />
          <div style={{ alignItems: 'flex-start' }} className={classes.inlineContainer}>
            <div className={classes.columnContainer}>
              <Typography className={classes.textSmallContainer} font="minion" size="tiny">
                Zapraszamy wszystkich entuzjastów architektury, sztuki, dobrego designu, Krakowa, a
                także każdego kto chciałaby współtworzyć Festiwal Otwarte Mieszkania!
                <br />
                <br />
                Wolontariat przy FOMie to okazja do:
                <br />
                <br />
                zdobycia doświadczenia przy współtworzeniu wydarzenia kulturalnego, pozyskania
                kontaktów branżowych, zaliczenia praktyk, pozyskania referencji, poznania ciekawych
                ludzi, a przede wszystkim bezcennych doświadczeń!
              </Typography>
              <ArrowIcon />
            </div>
            <Typography className={classes.textSmallContainer} font="minion" size="tiny">
              Czym (między innymi) zajmują się wolontariusze?
              <br />
              <br />
              Przed festiwalem: współtworzeniem programu wydarzeń dodatkowych festiwalu, kolportażem
              materiałów promocyjnych;
              <br />
              <br />
              Podczas festiwalu: opieką nad udostępnionymi lokalizacjami, opieką nad gośćmi i
              uczestnikami festiwalu, udzielaniem informacji o festiwalu, organizacją wydarzeń
              towarzyszących (impreza, koncert, warsztaty dla dzieci), reportażem (foto/video). z
              festiwalu.
              <br />
            </Typography>
          </div>
          <Divider hidden height={60} />
        </CenterContainer>
        <Breadcrumbs heading="Wolontariat" sections={[{ val: 'kontakt', selected: true }]} />
        <CenterContainer>
          <div id="kontakt" className={classes.fontLarger}>
            Masz pytania?
          </div>
          <div className={classes.inlineContainer}>
            <ArrowIcon classes={{ root: classes.arrowLeft }} />
            <img className={classes.paddedImg} height="150px" src={marta} alt="Napisz do marty" />
            <ArrowIcon classes={{ root: classes.arrowRight }} />
          </div>
          <Typography font="minion" size="tiny">
            Napisz do Vanessy!
          </Typography>
          <ExternalLinkButton to="mailto:wolo@fomkrakow.pl">
            {'< wolo@fomkrakow.pl >'}
          </ExternalLinkButton>
        </CenterContainer>
      </>
    );
  };
}

export default injectStyles(styles)(WesprzyjNas);
