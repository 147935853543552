import React from 'react';

const escapeNewLines = text => (text
  ? text
    .replace(/<br \/>|<br>|<br\/>/, '\n')
    .replace(/<.+>|\r$/g, '')
    .split('\n')
    .map((item, i) => (
      <span key={i}>
        {item}
        <br />
      </span>
    ))
  : '');

export default escapeNewLines;
