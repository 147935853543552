import React from 'react';
import injectStyles from 'react-jss';
import festiwal1 from '../../assets/images/festiwal1.png';
import playIcon from '../../assets/play-button.png';
import BlockContainer from '../BlockContainer';
import ProgramLink from '../ProgramLink';
import Divider from '../styledElements/Divider';
import { TitleContaierNoQuery } from '../TitleContainer';
import withPlayerLink from '../withPlayerLink';
import Typography from '../styledElements/Typography';

const styles = ({ palette: { red }, brakePoint: { mobile } }) => ({
  inlineContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    [mobile]: {
      flexFlow: 'column',
      width: '100%',
    },
  },
  imageContianer: {
    width: 300,
    marginRight: 60,
    [mobile]: {
      margin: 0,
      width: '100%',
    },
  },
  image: {
    width: '100%',
  },
  playButton: {
    backgroundColor: red,
    width: 120,
    height: 120,
    borderRadius: '50%',
    backgroundImage: `url(${playIcon})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  linkContainer: {
    width: '100%',
    textAlign: 'right',
    marginTop: -15,
  },
  centerContent: {
    width: '100%',
    textAlign: 'center',
  },
  col2: {
    [mobile]: {
      width: '100%',
    },
  },
});

const Idea = injectStyles(styles)(({ classes }) => {
  const PlayerButton = withPlayerLink(({ url }) => (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <div className={classes.playButton} />
    </a>
  ));
  return (
    <>
      <div className={classes.inlineContainer}>
        <div className={classes.imageContianer}>
          <img className={classes.image} src={festiwal1} alt="festiwal1" />
        </div>
        <div className={classes.col2}>
          <TitleContaierNoQuery fieldTextTrimmed="Zajrzyj z nami do mijanych na co dzień budynków i zainspiruj się tym, jak żyją i mieszkają inni. Zapraszamy na kolejną edycję Festiwalu Otwarte Mieszkania!" />
          <div className={classes.linkContainer}>
            <ProgramLink />
          </div>
          <Divider hidden height={30} />
          <div className={classes.inlineContainer}>
            <PlayerButton blockId="link_player" />
            <Typography className={classes.centerContent} font="arial" size="tiny">
              {'<zobacz relację z poprzedniej edycji>'}
            </Typography>
          </div>
        </div>
      </div>
      <Divider hidden height={30} />
      <BlockContainer blockId="opis_idea" />
      <Divider hidden height={60} />
    </>
  );
});

export default Idea;
