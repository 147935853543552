import React from 'react';
import injectStyles from 'react-jss';
import classnames from 'classnames';

const styles = ({ palette: { green, red } }) => ({
  root: {
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    margin: 5,
  },
  ok: {
    backgroundColor: green,
  },
  notOk: {
    backgroundColor: red,
  },
});
const IconOKNOOK = injectStyles(styles)(({ classes, isOk }) => (
  <div className={classnames(classes.root, isOk ? classes.ok : classes.notOk)}>
    {isOk ? ':)' : ':('}
  </div>
));

export default IconOKNOOK;
