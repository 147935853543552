import React, { Component } from 'react';
import _ from 'lodash';

const ViewpointContext = React.createContext({
  mobile: false,
  tablet: false,
  desktop: true,
  width: 1200,
});

class ClientWidth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: false, tablet: false, desktop: true, width: 1200,
    };
    this.debouncedResize = _.debounce(this.handleResize.bind(this), 200);
  }

  componentWillMount() {
    this.handleResize();
  }

  componentDidMount() {
    window.addEventListener('resize', this.debouncedResize);
  }

  componentWillUnmount() {
    window.addEventListener('resize', this.debouncedResize);
  }

  handleResize() {
    const x = window.innerWidth
      || document.documentElement.clientWidth
      || document.querySelector('body').clientWidth;
    const mobile = x < 721;
    const tablet = !mobile && x < 1101;
    const desktop = !mobile && !tablet;
    this.setState({
      mobile, tablet, desktop, width: x,
    });
  }

  render = () => {
    const { children } = this.props;
    const sizes = this.state;
    return <ViewpointContext.Provider value={sizes}>{children}</ViewpointContext.Provider>;
  };
}

const withClient = WrappedComponent => props => (
  <ViewpointContext.Consumer>
    {things => <WrappedComponent {...props} client={things} />}
  </ViewpointContext.Consumer>
);

export { withClient };

export default ClientWidth;
