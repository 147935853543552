import React from 'react';
import injectStyles from 'react-jss';
import classnames from 'classnames';

const styles = ({
  palette: { black, red, green },
  font: {
    family: { arial },
  },
}) => ({
  root: {
    width: '120px',
    height: '120px',
    position: 'absolute',
    transform: 'translate(50%, -50%)',
    backgroundColor: green,
    right: '50%',
    margin: 'auto',
    borderRadius: '50%',
    WebkitAppearance: 'none',
    outline: 'none',
    cursor: 'pointer',
    color: black,
    border: 'none',
    textAlign: 'center',
    padding: 0,
    fontSize: '20px',
    fontFamily: arial,
    userSelect: 'none',
    '&:hover': {
      outline: 'none',
      color: black,
    },
    '&:active': {
      color: black,
    },
  },
  opened: {
    backgroundColor: green,
    paddingTop: '30px',
    right: 0,
    left: 'auto',
    paddingRight: 40,
    transitionDuration: 300,
    transitionProperty: 'right, padding-top, padding-right, background-color',
    transitionTimingFunction: 'cubic-bezier(0.6,-0.06, 0.4, 1.09)',
  },
  closed: {
    paddingBottom: '40px',
    backgroundColor: green,
    transitionDuration: 300,
    transitionProperty: 'right, padding-top, padding-right, background-color',
    transitionTimingFunction: 'cubic-bezier(0.6,-0.06, 0.4, 1.09)',
  },
});
const FancyMenuButton = injectStyles(styles)(({
  classes, openedFactor, onClick,
}) => (
  <button
    type="button"
    onClick={onClick}
    className={classnames(classes.root, openedFactor > 0.9 ? classes.opened : classes.closed, 'nodrag')}
  >
    <span>{openedFactor > 0.9 ? 'x' : 'menu'}</span>
  </button>
));

export default FancyMenuButton;
