import React from 'react';
import injectStyles from 'react-jss';
import classnames from 'classnames';

const styles = ({ palette: { black } }) => ({
  root: {
    WebkitAppearance: 'none',
    outline: 'none',
    textAlign: 'left',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    color: black,
    border: 'none',
    padding: 0,
    '&:hover': {
      outline: 'none',
      color: black,
    },
    '&:active': {
      color: black,
    },
  },
});
const BlankButton = injectStyles(styles)(({
  classes, children, className, ...props
}) => (
  <button type="button" className={classnames(classes.root, className)} {...props}>
    {children}
  </button>
));

export default BlankButton;
