import React from 'react';
import injectStyles from 'react-jss';
import classnames from 'classnames';
import Typography from './styledElements/Typography';

const styles = () => ({
  root: {
    position: 'relative',
  },
  rotated: {
    transform: 'rotate(270deg)',
    position: 'absolute',
    left: '-3px',
    bottom: '0',
    transformOrigin: 'bottom left',
  },
  right: {
    transformOrigin: 'top right',
    top: '0',
    right: '-3px',
    bottom: 'auto',
    left: 'auto',
  },
  bottom: {
    transform: 'none',
    bottom: '-30px',
    left: '0',
  },
});

const ImageCaptionContainer = injectStyles(styles)(
  ({
    classes, src, className, author, height, width, alt, right, bottom,
  }) => (
    <div className={classnames(classes.root, className)}>
      <Typography
        className={classnames(classes.rotated, right && classes.right, bottom && classes.bottom)}
        font="arial"
        size="tiny"
      >
        {author}
      </Typography>
      <img
        style={{ height: height ? '100%' : 'auto', width: width ? '100%' : 'auto' }}
        src={src}
        alt={alt}
      />
    </div>
  ),
);

export default ImageCaptionContainer;
