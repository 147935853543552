import React from 'react';
import injectStyles from 'react-jss';
import classnames from 'classnames';
import BlankButton from '../../styledElements/BlankButton';
import BlockWithArrow from '../../styledElements/BlockWithArrow';
import Typography from '../../styledElements/Typography';
import triangleDown from '../../../assets/triangle-down.svg';

const styles = ({
  palette: { black, green },
  transition: { duration, effect },
  brakePoint: { mobile },
}) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  header: {
    borderBottom: `1px solid ${black}`,
    padding: '10px 50px',
    cursor: 'pointer',
    transition: `background-color ${duration} ${effect}`,
    willChange: 'background-color',
    userSelect: 'none',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: green,
    },
    [mobile]: {
      padding: '10px 0px',
    },
  },
  elements: {
    margin: 40,
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    [mobile]: {
      margin: 10,
    },
  },
  element: {
    flex: '1 0 31%',
    minWidth: 200,
    maxWidth: 310,
    position: 'relative',
    paddingLeft: 15,
    paddingTop: 15,
  },
  triangle: {
    width: 25,
    height: 25,
    transition: `transform ${effect} ${duration}`,
  },
  activeTriangle: {
    transform: 'rotate(180deg)',
  },
});

const Section = injectStyles(styles)(({
  classes, name, faqs, onClick, collapsed,
}) => (
  <div className={classes.root}>
    <BlankButton onClick={onClick} className={classes.header}>
      <Typography font="minion" size="medium">
        {`${name}:`}
      </Typography>
      <img
        className={classnames(classes.triangle, !collapsed && classes.activeTriangle)}
        src={triangleDown}
        alt="triangle down"
      />
    </BlankButton>
    {!collapsed && (
      <div className={classes.elements}>
        {faqs.map(({ fieldQuestion, fieldAnswer }, i) => (
          <BlockWithArrow
            key={i}
            classes={{ root: classes.element }}
            header={fieldQuestion}
            text={fieldAnswer}
          />
        ))}
        <div className={classes.element} />
        <div className={classes.element} />
        <div className={classes.element} />
      </div>
    )}
  </div>
));

export default Section;
