import React, { Component } from 'react';
import ReactGa from 'react-ga';
import injectStyles from 'react-jss';
import { withClient } from '../../ClientWidth';
import Breadcrumbs from '../Breadcrumbs';
import Faq from './FAQ';
import Harmonogram from './Harmonogram';
import Divider from '../styledElements/Divider';
import DateContainer from './DateContainer';
import Typography from '../styledElements/Typography';

const styles = ({ brakePoint: { tablet, mobile } }) => ({
  root: {},
  inline: {
    display: 'flex',
    flexFlow: 'row',
  },
  col1: {
    flex: '0 0 220px',
    [tablet]: {
      flex: '0 0 0',
    },
  },
  col2: {
    flex: '1 1 auto',
    margin: '20px 5%',
    [mobile]: {
      margin: '20px auto',
    },
  },
  paddedLine: {
    margin: '70px 0',
  },
});

class PraktyczneInformacje extends Component {
  componentDidMount() {
    ReactGa.pageview('/praktyczne-informacje');
  }

  render = () => {
    const {
      classes,
      client: { mobile },
    } = this.props;
    return (
      <div>
        <div className={classes.inline}>
          <div className={classes.col1}>
            <Breadcrumbs
              primary
              vertical
              heading="Praktyczne informacje"
              link="/praktyczne-informacje"
              sections={[
                { val: 'FAQ', selected: true },
                { val: 'Harmonogram rejestracji' },
              ]}
            />
          </div>
          <div id="FAQ" className={classes.col2}>
            {mobile && (
              <Typography font="minion" size="medium">
                {'FAQ:'}
              </Typography>
            )}
            <Faq />
          </div>
        </div>
        <div className={classes.inline}>
          <div className={classes.col1}>
            <Breadcrumbs
              vertical
              heading="Praktyczne informacje"
              sections={[
                { val: 'FAQ' },
                { val: 'Harmonogram rejestracji', selected: true },
              ]}
            />
          </div>
          <div id="Harmonogram rejestracji" className={classes.col2}>
            {mobile && (
              <Typography font="minion" size="medium">
                {'Harmonogram rejestracji:'}
              </Typography>
            )}
            <Harmonogram />
          </div>
        </div>
        <Divider classes={{ override: classes.paddedLine }} />
        <DateContainer />
      </div>
    );
  };
}

export default withClient(injectStyles(styles)(PraktyczneInformacje));
