import React from 'react';
import injectStyles from 'react-jss';
import fomlogo from '../assets/images/logo-black.png';
import classnames from 'classnames';

const styles = ({ palette }) => ({
  root: {
    backgroundColor: palette.red,
    width: 100,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    position: 'absolute',
    userSelect: 'none',
  },
  image: {
    height: 30,
  },
});

const CircleIcon = injectStyles(styles)(({ classes, className, customIcon }) => (
  <div className={classnames(classes.root, className)}>
    <img className={classes.image} src={customIcon || fomlogo} alt="fomlogo" />
  </div>
));

export default CircleIcon;
