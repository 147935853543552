import React from 'react';
import injectStyles from 'react-jss';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import EventDatesWithQuery from './EventDates';
import MainMenu from './MainMenu';
import Logo from '../Logo';

const styles = ({
  palette: { red, black },
  font: {
    family: { questa },
  },
  brakePoint: { mobile, tablet },
}) => ({
  root: {
    width: '100%',
    height: '80px',
    backgroundColor: red,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    position: 'relative',
    [mobile]: {
      height: 50,
    },
    '@media (max-width: 360px)': {
      padding: '0 5px',
      letterSpacing: -0.3,
    },
  },
  logoText: {
    textDecoration: 'none',
    fontFamily: questa,
    fontSize: 20,
    color: black,
    letterSpacing: -0.2,
    marginLeft: 30,
    position: 'relative',
  },
  logoImg: {
    height: 25,
    position: 'absolute',
    top: 0,
    left: -23,
  },
  dates: {
    [mobile]: {
      position: 'absolute',
      right: 20,
      fontSize: '18px',
      letterSpacing: '-0.7px',
    },
    '@media (max-width: 360px)': {
      letterSpacing: -1.1,
      right: 10,
    },
  },
  textShort: {
    display: 'none',
    [tablet]: {
      display: 'initial',
    },
  },
  textLong: {
    display: 'block',
    [tablet]: {
      display: 'none',
    },
  },
});

const AppHeader = injectStyles(styles)(({ classes }) => (
  <div className={classes.root}>
    <Link className={classes.logoText} to="/">
      <Logo color="dark" className={classes.logoImg} />
      <span className={classnames(classes.textShort)}>FOM Kraków</span>
      <span className={classnames(classes.textLong)}>Festiwal Otwarte Mieszkania Kraków</span>
    </Link>
    <EventDatesWithQuery classes={{ root: classes.dates }} />
    <MainMenu />
  </div>
));

export default AppHeader;
