import React from 'react';
import injectStyles from 'react-jss';
import { Link } from 'react-router-dom';
import withVolunteersQuery from './withVolunteersQuery';
import Typography from '../styledElements/Typography';
import CenterContainer from '../CenterContainer';
import TrimmedContainer from '../styledElements/TrimmedContainer';
import Breadcrumbs from '../Breadcrumbs';
import fotawolo from '../../assets/fotawolo2017_m.jpg';
import Divider from '../styledElements/Divider';
import ArrowIcon from '../styledElements/ArrowIcon';
import FirstOfLetter from './FirstOfLetter';
import heart from '../../assets/heart.png';

const styles = ({
  font: { size, family },
  palette: { green, black },
  brakePoint: { mobile, tablet },
}) => ({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
    marginTop: 20,
    width: '100%',
    justifyContent: 'space-between',
  },
  centerContainer: {
    marginTop: 40,
    marginBottom: 40,
  },
  titleContainer: {
    alignSelf: 'flex-start',
    position: 'relative',
    '&:after': {
      content: '""',
      fontFamily: family.arial,
      fontSize: size.medium,
      position: 'absolute',
      right: -30,
      top: '50%',
      transform: 'translateY(-50%)',
      height: '26px',
      backgroundImage: `url(${heart})`,
      width: '26px',
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
    },
  },
  cell: {
    flex: '0 1 22%',
    minWidth: 100,
    marginBottom: 10,
    display: 'flex',
    flexFlow: 'row nowrap',
    [tablet]: {
      flex: '0 1 30%',
    },
    [mobile]: {
      flex: '0 1 40%',
    },
    "@media (max-width: 500px)": {
      flex: '0 1 auto',
    }
  },
  fullWidth: {
    width: '100%',
  },
  dolaczContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    width: 620,
    color: black,
    justifyContent: 'space-between',
    margin: '30px 0',
    fontFamily: family.questa,
    alignItems: 'center',
    [mobile]: {
      width: 280,
      flexFlow: 'row wrap',
    },
  },
  text1: {
    backgroundColor: green,
    padding: 10,
    fontSize: size.medium,
    [mobile]: {
      flex: '0 1 200px',
      fontSize: 21,
    },
  },
  text2: {
    fontSize: size.tiny,
    textDecoration: 'none',
    color: black,
    '&:hover': {
      textDecoration: 'underline',
    },
    [mobile]: {
      flex: '0 1 80%',
      textAlign: 'center',
    },
  },
  arrow: {
    transform: 'rotateZ(-90deg)',
    height: 9,
  },
  arrow1: {
    position: 'relative',
    left: '-30px',
    top: '2px',
    [mobile]: {
      left: '40px',
      transform: 'rotate(90deg)',
      transformOrigin: 'center left',
      flex: '0 1 75px',
    },
  },
  arrow2: {
    position: 'relative',
    left: '-50px',
    top: '-4px',
    [mobile]: {
      left: 'auto',
      flex: '0 1 95%',
      transform: 'rotate(90deg)',
      textAlign: 'right',
      transformOrigin: 'center right',
      right: '13px',
      top: '-1px',
    },
  },
  arrow3: {
    position: 'relative',
    left: '-60px',
    top: '5px',
    [mobile]: {
      left: 'auto',
      flex: '0 1 95%',
      transform: 'rotate(90deg)',
      textAlign: 'right',
      transformOrigin: 'center right',
      right: '-12px',
      top: '1px',
    },
  },
  arrow4: {
    position: 'relative',
    [mobile]: {
      flex: '0 1 10px',
      order: 1,
      right: 10,
      transform: 'rotate(180deg)',
    },
  },
});

const Wolontarusze = ({ volunteers, classes }) => (
  <div id="Wolontariusze">
    <Breadcrumbs
      heading="O nas"
      sections={[
        { val: 'Stowarzyszenie' },
        { val: 'Zespół' },
        { val: 'Wolontariusze', selected: true },
      ]}
    />
    <CenterContainer className={classes.centerContainer}>
      <TrimmedContainer>
        <div className={classes.titleContainer}>
          <Typography size="medium" font="questa">
            Wolontariusze
          </Typography>
        </div>
        <div className={classes.root}>
          {volunteers.map((volunteer, i) => (volunteer ? (
            <div key={volunteer.id} className={classes.cell}>
              <FirstOfLetter color={i % 5} letter={volunteer.firstOfName} />
              <Typography font="arial" size="tiny">
                {`${volunteer.fieldFirstname} ${volunteer.fieldLastname}`}
              </Typography>
            </div>
          ) : null))}
        </div>
        <Divider hidden height={100} />
        <div className={classes.fullWidth}>
          <img className={classes.fullWidth} src={fotawolo} alt="zespol" />
        </div>
        <div className={classes.dolaczContainer}>
          <div className={classes.text1}>Potrzebujemy Was</div>
          <ArrowIcon classes={{ root: classes.arrow1, arrow: classes.arrow }} />
          <ArrowIcon classes={{ root: classes.arrow2, arrow: classes.arrow }} />
          <ArrowIcon classes={{ root: classes.arrow3, arrow: classes.arrow }} />
          <ArrowIcon classes={{ root: classes.arrow4, arrow: classes.arrow }} />
          <Link className={classes.text2} to="/wolontariat">
            {'< Dołącz do naszego zespołu >'}
          </Link>
        </div>
      </TrimmedContainer>
    </CenterContainer>
  </div>
);

export default withVolunteersQuery(injectStyles(styles)(Wolontarusze));
