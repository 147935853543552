import React from 'react';
import injectStyles from 'react-jss';
import Range from 'rc-slider/lib/Range';
import 'rc-slider/assets/index.css';

const styles = () => ({
  root: {},
});

const Slider = injectStyles(styles)(({ theme: { palette }, handleChange, value }) => (
  <Range
    min={540}
    max={1260}
    step={30}
    value={value}
    allowCross={false}
    pushable
    railStyle={{
      backgroundColor: 'transparent',
      height: 2,
      borderBottom: `1px dashed ${palette.black}`,
    }}
    handleStyle={[
      {
        borderRadius: 0,
        border: 'none',
        backgroundColor: 'black',
        height: 16,
        width: 12,
        marginTop: -7,
        outline: 'none',
        boxShadow: 'none',
      },
      {
        borderRadius: 0,
        border: 'none',
        backgroundColor: 'black',
        height: 16,
        width: 12,
        marginTop: -7,
        outline: 'none',
        boxShadow: 'none',
      },
    ]}
    trackStyle={[
      {
        backgroundColor: 'transparent',
      },
    ]}
    onChange={handleChange}
  />
));

export default Slider;
