import React, { Component } from 'react';
import ReactGa from 'react-ga';
import injectStyles from 'react-jss';
import scroll from '../../assets/images/hp/scroll.gif';
import { withClient } from '../../ClientWidth';
import { MapDatesQuery } from '../AppHeader/EventDates';
import MainMenu from '../AppHeader/MainMenu';
import Logo from '../Logo';
import SocialLinks from '../SocialLinks';
import Typography from '../styledElements/Typography';
import ContainerWithMagic from './ContainerWithMagic';
import { updatedAtYear } from '../../config.json';

const styles = ({ font: { size, family }, palette: { beige, black }, brakePoint: { mobile, tablet } }) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  menuContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: 60,
  },
  logoContainer: {
    fontFamily: family.questa,
    color: black,
    fontSize: 40,
    lineHeight: '1.1',
    margin: '10px 0',
    [mobile]: {
      fontSize: 25,
    },
  },
  leftBottom: {
    position: 'absolute',
    bottom: 20,
    left: 40,
    zIndex: 1,
    [mobile]: {
      left: '20px',
      bottom: '40px',
    },
  },
  logoImg: {
    height: '30px',
    margin: '0px 0px 3px 3px',
    [mobile]: {
      height: '25px',
      margin: '0px 0px -7px 1px',
    },
  },
  scrollIconContainer: {
    position: 'absolute',
    top: 40,
    zIndex: 1,
    left: 70,
    alignItems: 'center',
    display: 'flex',
    [mobile]: {
      top: 20,
      left: 20,
    },
  },
  scrollIcon: {
    height: 15,
    marginRight: 10,
  },
  datesContainer: {
    display: 'flex',
    position: 'absolute',
    right: '15%',
    top: '65%',
    zIndex: '1',
    color: black,
    fontFamily: family.raleway,
    fontSize: 44,
    lineHeight: 1,
    borderTop: ` 1px solid ${beige}`,
    width: 300,
    flexFlow: 'column nowrap',
    padding: '0 30px',
    [mobile]: {
      right: '10px',
      left: 'auto',
      fontSize: '25px',
      width: '170px',
      padding: '0 15px',
      bottom: '158px',
      top: 'auto',
      height: 55,
    },
    '&:before': {
      content: `"${updatedAtYear}r."`,
      textAlign: 'center',
      top: '-27px',
      position: 'absolute',
      fontSize: '15px',
      left: '50%',
      transform: 'translateX(-50%)',
      [mobile]: {
        top: -18,
        fontSize: '13px',
      },
    },
  },
  dateItem: {
    '&:first-child': {
      textAlign: 'left',
    },
    '&:last-child': {
      textAlign: 'right',
    },
  },
  socialLinks: {
    display: 'flex',
    fontSize: size.tiny,
    flexFlow: 'row nowrap',
    alignItems: 'center',
    [mobile]: {
      position: 'fixed',
      top: '10px',
      writingMode: 'vertical-lr',
      right: '3px',
      fontSize: '15px',
      letterSpacing: '1px',
    },
  },
});

class index extends Component {
  componentDidMount() {
    ReactGa.pageview('/home');
  }

  render = () => {
    const { classes, client } = this.props;

    return (
      <div className={classes.root}>
        <div title="scroll for more" className={classes.scrollIconContainer}>
          <img className={classes.scrollIcon} src={scroll} alt="scroll icon" />
          <Typography size="small" font="questa">
            scroll for more
          </Typography>
        </div>
        <div className={classes.datesContainer}>
          <MapDatesQuery className={classes.dateItem} />
        </div>
        <div className={classes.leftBottom}>
          <Logo color="dark" className={classes.logoImg} />
          <div className={classes.logoContainer}>
            Festiwal
            <br />
            Otwarte Mieszkania
            <br />
            Kraków
          </div>
          <SocialLinks classes={{ root: classes.socialLinks }} />
        </div>
        <div className={classes.menuContainer}>
          <MainMenu column />
        </div>
        <ContainerWithMagic client={client} />
      </div>
    );
  };
}

export default withClient(injectStyles(styles)(index));
