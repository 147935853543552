import React from 'react';
import injectStyles from 'react-jss';
import Typography from '../../styledElements/Typography';
import withDataQuery from './withDataQuery';
import escapeNewLines from '../../../utils/escapeNewLines';

const styles = ({ brakePoint: { mobile } }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row wrap',
  },
  elem: {
    margin: 20,
    [mobile]: {
      margin: 10,
    },
  },
});

const TextBlocks = ({ classes, cells }) => {
  if (!cells) return null;
  const blocks = cells.map(
    (cell, i) => cell.value && (
    <Typography key={i} className={classes.elem}>
      {escapeNewLines(cell.value)}
    </Typography>
    ),
  );
  return <div className={classes.root}>{blocks}</div>;
};

const DaneStowarzyszenia = withDataQuery(injectStyles(styles)(TextBlocks));

export default DaneStowarzyszenia;
