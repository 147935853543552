import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import ReactGa from 'react-ga';
import smoothscroll from 'smoothscroll-polyfill';
import Festiwal from './components/Festiwal';
import HomePage from './components/HomePage';
import Kontakt from './components/Kontakt';
import RootContainer from './components/RootContainer';
import ONas from './components/ONas';
import Program from './components/Program';
import PraktyczneInformacje from './components/PraktyczneInformacje';
import MapaRzemieslnikow from './components/MapaRzemieslnikow';
import MapaVintage from './components/MapaVintage';
import Wolontariat from './components/Wolontariat';
import CookiesBaner from './CookiesBanner';

ReactGa.initialize('UA-116589860-3', { debug: true });
ReactGa.set({ anonymizeIp: true });

smoothscroll.polyfill();

const NotFound = () => <h3>{'No such page (yet? < ✈️ >)'}</h3>;

const App = () => (
  <BrowserRouter>
    <CookiesBaner />
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/mapa-rzemieslnikow" component={MapaRzemieslnikow} />
      <Route exact path="/mapa-vintage" component={MapaVintage} />
      <RootContainer>
        <Route exact path="/festiwal" component={Festiwal} />
        <Route exact path="/kontakt" component={Kontakt} />
        <Route exact path="/o-nas" component={ONas} />
        <Route exact path="/program/:id?" component={Program} />
        <Route exact path="/wolontariat" component={Wolontariat} />
        <Route exact path="/praktyczne-informacje" component={PraktyczneInformacje} />
      </RootContainer>
      <Route path="/" component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default App;
