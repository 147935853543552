import React, { Component } from "react";
import ReactGa from "react-ga";
import List from "./List";
import Filters from "./Filters";
import withUrlProps from "../../utils/withUrlProps";
import DateContainer from "./DateContainer";
import ScrollTop from "./ScrollTop";
import Typography from "../styledElements/Typography";
import ArrowIcon from "../styledElements/ArrowIcon";

const initialFilters = {
  daty: [],
  godzinaStart: 540,
  godzinaKoniec: 1260,
  dzielnica: [],
  lokalizacja: true,
  wydarzenie: true,
  opis: ""
};

class Program extends Component {
  componentDidMount() {
    ReactGa.pageview("/program");
  }

  render = () => {
    const { filters, saveFilters } = this.props;
    return (
      <div>
        <Filters
          filters={filters}
          saveFilters={saveFilters}
          resetFilters={() => saveFilters(initialFilters)}
        />
        <Typography
          style={{
            display: "flex",
            margin: "40px 0 20px",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <ArrowIcon />
          {"    "}
          Informacja o dokładnym adresie znajduje się tylko na bilecie
          otrzymanym po rejestracji do danego miejsca / na dane wydarzenie.
          Osoby będące na liście rezerwowej otrzymają adres wraz z informacją o
          możliwości udziału.
        </Typography>
        <List filters={filters} />

        <DateContainer />
        <ScrollTop />
      </div>
    );
  };
}

export default withUrlProps(initialFilters)(Program);
