import React from 'react';
import injectStyles from 'react-jss';

const styles = ({ palette }) => ({
  wrapper: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: palette.green,
    zIndex: 100000,
    fontSize: '12px',
  },
  paragraph: {
    margin: 10,
    color: 'black',
  },
  button: {
    padding: '5px 30px',
    margin: '0px 0px 10px 10px',
    border: '2px solid black',
    WebkitAppearance: 'none',
    outline: 'none',
    textAlign: 'left',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    color: palette.black,
    '&:hover': {
      outline: 'none',
      color: palette.black,
    },
    '&:active': {
      color: palette.black,
    },
  },
  link: {
    color: 'black',
    border: '0',
    margin: '0',
    cursor: 'pointer',
    padding: '3px',
    outline: 'none',
    fontSize: '12px',
    background: 'transparent',
    fontFamily: 'Arial, Helvetica, sans-serif',
    pointerEvents: 'auto',
    textDecoration: 'none',
  },
});
class CookiesBaner extends React.Component {
  saveAnswer = () => {
    localStorage.setItem('CookiesZgoda', true);
    this.forceUpdate();
  };

  render = () => {
    const { classes } = this.props;
    const userAlreadyAgreed = localStorage.getItem('CookiesZgoda');
    return !userAlreadyAgreed ? (
      <div className={classes.wrapper} style={{}}>
        <p className={classes.paragraph}>
          Używamy plików cookies (ciasteczka) w celu zapewnienia funkcji mediów społecznościowych i
          analizy naszego ruchu. Udostępniamy również informacje na temat korzystania z naszej
          witryny za pośrednictwem mediów społecznościowych (Facebook, Instagram) i partnerów
          analitycznych (Google Analytics), którzy mogą łączyć ją z innymi informacjami, które im
          przekazaliście lub które zgromadzili w związku z korzystaniem z ich usług. W każdej chwili
          możesz usunąć swoje dane z naszej bazy w Analytics instalując odpowiednią wtyczkę, np.:
          <a
            href="https://tools.google.com/dlpage/gaoptout"
            rel="noopener noreferrer"
            className={classes.link}
            target="_blank"
          >
            {' '}
            https://tools.google.com/dlpage/gaoptout
          </a>
          .
        </p>
        <p className={classes.paragraph}>
          Zgadzam się na przechowywanie na urządzeniu, z którego korzystam tzw. plików cookies oraz
          na przetwarzanie moich danych osobowych pozostawianych w czasie korzystania przeze mnie ze
          stron internetowych lub serwisów oraz innych parametrów zapisywanych w plikach cookies w
          celach analitycznych przez Stowarzyszenie Otwarte Mieszkania.
        </p>
        <button className={classes.button} type="button" onClick={this.saveAnswer}>
          OK
        </button>
      </div>
    ) : null;
  };
}

export default injectStyles(styles)(CookiesBaner);
