import React, { useEffect } from 'react';
import { useSpring } from 'react-spring/three';
import { Canvas } from 'react-three-fiber';
import _ from 'lodash';
import Scene from './Scene';

const Container = ({ classes, client }) => {
  const [{ left, mouse }, set] = useSpring(() => ({
    left: 0,
    mouse: [0, 0, 0],
  }));
  function onMouseMove({ clientX: x, clientY: y }) {
    set({ mouse: [x - window.innerWidth / 2, y - window.innerHeight / 2, 0] });
  }
  function onScroll(e) {
    set({ left: e.target.scrollLeft + e.target.scrollTop });
  }

  useEffect(() => {
    let interval;
    const handleDeviceOrientation = (event) =>
      set({
        mouse: [
          _.clamp(event.gamma * 8, -360, 360), // from -45 to 45
          _.clamp(event.beta * 16 - 720, -360, 360) // from 22.5 to 67.5
        ]
      });
    if (client.mobile) {
      if (window.DeviceOrientationEvent) {
        window.addEventListener("deviceorientation", handleDeviceOrientation);
      } else {
        interval = setInterval(() => {
          const x = Math.floor((Math.random() - 0.5) * window.innerWidth);
          const y = Math.floor((Math.random() - 0.5) * window.innerHeight);
          set({ mouse: [x, y] });
        }, 3000);
      }
    }
    return () => {
      clearInterval(interval);
      window.removeEventListener("deviceorientation", handleDeviceOrientation);
    };
  }, [set, client.mobile]);
  return (
    <>
      <Canvas className={classes.canvas}>
        <ambientLight intensity={2} color="white" />
        <Scene left={left} mouse={mouse} client={client} />
      </Canvas>
      <div className={classes.scrollContianer} onScroll={onScroll} onMouseMove={onMouseMove}>
        <div
          style={{
            width: '300vw',
            height: `calc(100vh + ${client.width * 2}px)`,
          }}
        />
      </div>
    </>
  );
};

export default Container;
