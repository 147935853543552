import React, { useEffect, useRef, useState } from "react";
import injectStyles from "react-jss";
import classnames from "classnames";
import moment from "moment";
import Item from "./Item";
import { withClient } from "../../ClientWidth";
import IconOKNOOK from "./IconOKNOOK";
import Typography from "../styledElements/Typography";
import _ from "lodash";

const styles = ({
  palette: { black, green, background },
  font: {
    family: { raleway }
  },
  brakePoint: { mobile, tablet }
}) => ({
  root: {
    marginBottom: "auto",
    "&:first-child": {
      [mobile]: {
        paddingLeft: 20
      }
    },
    "&:last-child": {
      [mobile]: {
        paddingRight: 40
      }
    }
  },
  detailsContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    top: "auto",
    marginTop: 40,
    borderTop: `1px solid ${black}`,
    borderBottom: `1px solid ${black}`,
    paddingBottom: 10,
    paddingRight: 50,
    height: "auto",
    [tablet]: {
      paddingRight: 20
    },
    [mobile]: {
      height: "auto",
      position: "relative",
      borderTop: "none",
      borderBottom: "none"
    }
  },
  img1Container: {
    position: "absolute",
    top: 30,
    right: 0,
    width: 380,
    zIndex: 1,
    height: 250,
    [tablet]: {
      width: 270,
      right: -100
    },
    [mobile]: {
      display: "none"
    }
  },
  img2Container: {
    position: "absolute",
    bottom: 40,
    left: 240,
    width: 300,
    height: 250,
    zIndex: 1,
    alignItems: "flex-end",
    display: "flex",
    [tablet]: {
      width: 270,
      height: 220,
      left: -80
    },
    [mobile]: {
      display: "none"
    }
  },
  image: {
    maxHeight: "100%",
    maxWidth: "100%",
    zIndex: 1
  },
  background: {
    position: "absolute",
    top: "30%",
    left: 190,
    bottom: "30%",
    right: 190,
    backgroundColor: green,
    zIndex: 0,
    [tablet]: {
      left: 130,
      right: 50,
      top: "25%",
      bottom: "25%"
    },
    [mobile]: {
      display: "none"
    }
  },
  itemContainer: {
    zIndex: 1,
    position: "relative",
    [mobile]: {
      position: "initial"
    }
  },
  datesContainer: {
    position: "initial",
    fontFamily: raleway,
    display: "flex",
    flexFlow: "column",
    backgroundColor: background,
    zIndex: 1,
    padding: "10px 30px 10px 10px",
    [mobile]: {
      display: "flex",
      flexFlow: "column nowrap",
      position: "initial",
      padding: "0 20px 15px 10px",
      right: "auto"
    }
  },
  dateContainer: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center"
  },
  day: {
    fontSize: 24
  },
  wrapperMax: {
    position: "initial",
    display: "flex",
    flexFlow: "column nowrap",
    [mobile]: {
      position: "initial",
      padding: "0 20px 0 0"
    }
  },
  wrapperMax2: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    [mobile]: {
      "&:first-child": {
        alignSelf: "flex-start"
      },
      "&:last-child": {
        alignSelf: "flex-end"
      }
    }
  },
  linkLink: {
    textDecoration: "none",
    color: black
  },
  hours: {
    maxWidth: 155
  },
  rowAlignRight: {
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    [mobile]: {
      flexFlow: "row wrap",
      justifyContent: "space-between"
    }
  }
});

const ItemWithDetails = injectStyles(styles)(
  withClient(
    ({
      classes,
      active,
      onClick,
      client: { mobile },
      id,
      openModal,
      ...props
    }) => {
      const {
        fieldStartDates,
        fieldRegistrationLink,
        fieldIsactive,
        registrationDays,
        fieldNoRegister
      } = props.data;

      const spareContentRef = useRef(null);
      const [spareContentHeight, setSpareContentHeight] = useState(0);
      useEffect(() => {
        const sch = _.get(spareContentRef, "current.clientHeight", 0);

        if (spareContentHeight !== sch) {
          setSpareContentHeight(sch);
        }
      }, [active, spareContentHeight]);

      const parsedDates = fieldStartDates.reduce((prev, curr) => {
        const date = moment.utc(curr.value, "YYYY-MM-DDTHH:mm:ss");
        const p = prev;
        if (Array.isArray(p[date.dayOfYear()])) {
          p[date.dayOfYear()].push(date.format("HH:mm"));
          return p;
        }
        p[date.dayOfYear()] = [date.format("HH:mm")];
        return p;
      }, {});

      const Rejestracja = () => (
        <div className={classes.wrapperMax2}>
          <IconOKNOOK isOk={registrationDays.length > 0} />
          <div>
            <Typography font="raleway">rejestracja:</Typography>
            <Typography size="small" font="raleway">
              {registrationDays.length === 1
                ? registrationDays.map(({ data }) => (
                    <span key={data}>
                      {moment
                        .utc(data, "YYYY-MM-DDTHH:mm:ss")
                        .format("DD.MM.YY")}
                      <br />
                      {moment.utc(data, "YYYY-MM-DDTHH:mm:ss").format("HH:mm")}
                    </span>
                  ))
                : registrationDays.map(({ data }) => (
                    <span key={data}>
                      {moment
                        .utc(data, "YYYY-MM-DDTHH:mm:ss")
                        .format("DD.MM.YY–HH:mm")}
                      <br />
                    </span>
                  ))}
            </Typography>
          </div>
        </div>
      );

      const RejestracjaLink = () =>
        fieldRegistrationLink ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            className={classes.linkLink}
            href={fieldRegistrationLink}
          >
            <Rejestracja />
          </a>
        ) : (
          <Rejestracja />
        );

      const Terminy = () =>
        Object.keys(parsedDates).length > 0 ? (
          <div className={classes.datesContainer}>
            Terminy:
            {Object.keys(parsedDates).map((dayOfYear) => (
              <div key={dayOfYear} className={classes.dateContainer}>
                <span className={classes.day}>
                  {moment().dayOfYear(dayOfYear).format("DD.MM")}
                </span>
                {"–"}
                <span className={classes.hours}>
                  {parsedDates[dayOfYear].join(", ")}
                </span>
              </div>
            ))}
          </div>
        ) : null;

      return (
        <div
          id={id}
          className={classnames(classes.root)}
          style={{ height: active ? spareContentHeight + 330 : "auto" }}
        >
          {!mobile && <Item onClick={onClick} active={active} {...props} />}
          {(mobile || active) && (
            <div ref={spareContentRef} className={classes.detailsContainer}>
              <div className={classes.itemContainer}>
                <Item
                  onClick={mobile && !active ? onClick : null}
                  openModal={openModal}
                  fullHeight
                  {...props}
                />
              </div>
              {fieldNoRegister ? (
                <div className={classes.rowAlignRight}>
                  <Terminy />
                  <div className={classes.wrapperMax}>
                    <div className={classes.wrapperMax2}>
                      <Typography size="small" font="raleway">
                        Wydarzenie <br /> bez rejestracji
                      </Typography>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={classes.rowAlignRight}>
                  <Terminy />
                  <div className={classes.wrapperMax}>
                    {!fieldIsactive ? (
                      <div className={classes.wrapperMax2}>
                        <IconOKNOOK isOk={fieldIsactive} />
                        <Typography size="small" font="raleway">
                          {"brak miejsc"}
                        </Typography>
                      </div>
                    ) : (
                      <RejestracjaLink />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      );
    }
  )
);

export default ItemWithDetails;
