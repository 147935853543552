import React from 'react';
import injectStyles from 'react-jss';

const styles = () => ({
  root: {
    width: '100%',
    margin: 'auto',
    display: 'flex',
    maxWidth: '900px',
    flexFlow: 'column nowrap',
    alignItems: 'center',
  },
});

const TrimmedContainer = injectStyles(styles)(({ classes, children }) => (
  <div className={classes.root}>{children}</div>
));

export default TrimmedContainer;
