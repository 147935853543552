import React from 'react';
import withDistrictsQuery from './withDistrictsQuery';
import Dropdown from '../Dropdown';

const DistrictsDropdown = withDistrictsQuery(
  ({
    className, value, districts, handleChange, name,
  }) => (
    <Dropdown
      multi
      name={name}
      className={className}
      value={value || []}
      handleChange={handleChange}
      options={[{ id: '', value: '- Wszystkie -' }].concat(districts)}
    />
  ),
);

export default DistrictsDropdown;
