import React from 'react';
import injectStyles from 'react-jss';
import classnames from 'classnames';
import Typography from './Typography';
import BlankButton from './BlankButton';

const styles = ({ palette: { black } }) => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    cursor: 'pointer',
    userSelect: 'none',
  },
  box: {
    width: 9,
    height: 9,
    border: `1px solid ${black}`,
    backgroundColor: 'transparent',
    margin: 2,
  },
  checked: {
    backgroundColor: black,
  },
});

const Checkbox = injectStyles(styles)(({
  classes, name, checked, onChange,
}) => {
  const onClick = () => onChange({ target: { name, value: !checked } });
  return (
    <BlankButton onClick={onClick} className={classes.root}>
      <span className={classnames(classes.box, checked && classes.checked)} />
      <Typography font="arial" size="tiny">
        {name}
      </Typography>
    </BlankButton>
  );
});

export default Checkbox;
