import moment from 'moment';
import React from 'react';
import injectStyles from 'react-jss';
import classnames from 'classnames';
import withQuery from './withQuery';

const styles = ({
  font: {
    family,
    size: { small },
  },
  palette: { black },
}) => ({
  root: {},
  font: {
    fontFamily: family.raleway,
  },
  view: {
    color: black,
    fontSize: small,
  },
});

const drawDates = (start, end) => {
  if (start.isSame(end, 'Y')) {
    if (start.isSame(end, 'M')) {
      return `${start.format('DD')}–${end.format('DD.MM')}`;
    }
    return `${start.format('DD.MM')}–${end.format('DD.MM.YYYY')}`;
  }
  return `${start.format('DD.MM.YYYY')}–${end.format('DD.MM.YYYY')}`;
};

const mapDates = eventDates => eventDates
  .map(({ fieldDateEnd, fieldDateStart }) => {
    const start = moment.utc(fieldDateStart.value, 'YYYY-MM-DD');
    const end = moment.utc(fieldDateEnd.value, 'YYYY-MM-DD');
    if (start.isSame(end, 'D') || !end.isValid()) {
      return start.format('DD.MM');
    }
    if (start.isBefore(end, 'D')) {
      return drawDates(start, end);
    }
    return drawDates(end, start);
  })
  .sort((a, b) => a.localeCompare(b));

const EventDates = injectStyles(styles)(({ eventDates, classes }) => (
  <div className={classnames(classes.font, classes.view, classes.root)}>
    {mapDates(eventDates).join(' | ')}
  </div>
));

const MapDatesQuery = withQuery(({ eventDates, className }) => mapDates(eventDates).map(term => (
  <div key={term} className={className}>
    {term}
  </div>
)));
export { EventDates, MapDatesQuery };

export default withQuery(EventDates);
