import React from 'react';
import injectStyles from 'react-jss';
import classnames from 'classnames';

const styles = ({ palette: { black } }) => ({
  root: {
    width: '100%',
    height: 2,
    display: 'block',
  },
  hidden: {
    backgroundColor: 'transparent',
    opacity: 0,
    margin: 0,
  },
  visible: {
    backgroundColor: black,
    opacity: 0.4,
    margin: '10px 0',
  },
});

const Divider = injectStyles(styles)(({ classes, height = 2, hidden }) => (
  <span
    style={{ height }}
    className={classnames(classes.root, hidden ? classes.hidden : classes.visible, classes.override)}
  />
));

export default Divider;
