import gql from 'graphql-tag';
import _ from 'lodash';
import React from 'react';
import { Query } from 'react-apollo';
import PhotoDesc from './PhotoDesc';
import PhotoDescContainer from './PhotoDescContainer';

const CZLONKOWIE_ZARZADU = gql`
  query {
    czlonkowieStowarzyszenia {
      results {
        uuid
        fieldAvatar {
          url
        }
        fieldMail
        fieldPhone
        fieldFirstname
        fieldLastname
        fieldShortBio
        fieldPosition
      }
    }
  }
`;

const ZespolContainer = () => (
  <Query query={CZLONKOWIE_ZARZADU}>
    {({ data }) => {
      if (!data || !data.czlonkowieStowarzyszenia) return null;
      const elems = data.czlonkowieStowarzyszenia.results.map((result) => {
        const parsed = {
          imgUrl: _.get(result, 'fieldAvatar.url'),
          name: result.fieldFirstname,
          lastName: result.fieldLastname,
          shortBio: result.fieldShortBio,
          mail: result.fieldMail,
          phone: result.fieldPhone,
          funct: result.fieldPosition,
        };
        return <PhotoDesc key={result.uuid} {...parsed} />;
      });
      return <PhotoDescContainer>{elems}</PhotoDescContainer>;
    }}
  </Query>
);

export default ZespolContainer;
