import React from 'react';
import injectStyles from 'react-jss';
import styles from '../styles';
import Container from './Container';

class HomePage extends React.Component {
  componentDidMount() {
    const { classes } = this.props;
    document.body.classList.add(classes.body);
    document.body.classList.add(classes.hpContainers);
    document.querySelector('#root').classList.add(classes.hpContainers);
    document.querySelector('html').classList.add(classes.hpContainers);
  }

  componentWillUnmount() {
    const { classes } = this.props;
    document.body.classList.remove(classes.body);
    document.body.classList.remove(classes.hpContainers);
    document.querySelector('#root').classList.remove(classes.hpContainers);
    document.querySelector('html').classList.remove(classes.hpContainers);
  }

  render = () => {
    const { classes, client } = this.props;
    return <Container classes={classes} client={client} />;
  };
}

export default injectStyles(styles)(HomePage);
