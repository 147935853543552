import './assets/styles/constants.css';

export default {
  font: {
    family: {
      arial: 'Arial, Helvetica, sans-serif',
      minion: '"Crimson-Roman"',
      questa: '"Questa Regular"',
      raleway: 'Raleway, sans-serif',
    },
    size: {
      tiny: 12,
      small: 18,
      medium: 26,
      large: 100,
      giant: 140,
      '@media (max-width: 720px)': {
        medium: 21,
        giant: 110,
      },
    },
  },
  palette: {
    red: '#e6af2e',
    green: '#999',
    black: '#000',
    beige: '#f0eced',
    bordo: '#c01a48',
    background: '#fff',
  },
  brakePoint: {
    mobile: '@media (max-width: 720px)',
    tablet: '@media (max-width: 1100px)',
  },
  transition: {
    duration: '0.3s',
    effect: 'cubic-bezier(0.6,-0.06, 0.4, 1.09)',
  },
};
