import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';

const ALL_QUERY = gql`
  query {
    faqView {
      results {
        id: nid
        fieldQuestion
        fieldAnswer
        fieldCathegory {
          entity {
            name
            id: tid
          }
        }
      }
    }
  }
`;

const withFaqQuery = WrappedComponent => props => (
  <Query query={ALL_QUERY}>
    {({ data }) => {
      const faqsObj = _.get(data, 'faqView.results', []).reduce((prev, curr) => {
        const cathegory = curr.fieldCathegory.entity.name;
        const base = prev;
        if (Array.isArray(prev[cathegory])) {
          base[cathegory].push(curr);
        } else {
          base[cathegory] = [curr];
        }
        return base;
      }, {});
      const faqsArr = Object.keys(faqsObj)
        .map(key => [key, faqsObj[key]])
        .sort(([a], [b]) => a.localeCompare(b));
      if (_.get(faqsArr, '[0][0]') === 'inne') {
        faqsArr.push(faqsArr.shift());
      }
      return <WrappedComponent faqs={faqsArr} {...props} />;
    }}
  </Query>
);

export default withFaqQuery;
