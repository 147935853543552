import classnames from 'classnames';
import React from 'react';
import injectSheet from 'react-jss';
import { HashLink } from 'react-router-hash-link';
import { withRouter } from 'react-router-dom';
import Typography from './styledElements/Typography';

const styles = ({ font: { size, family }, brakePoint: { tablet }, palette: { black } }) => ({
  root: {
    fontSize: size.tiny,
    fontFamily: family.arial,
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    padding: 10,
    [tablet]: {
      writingMode: 'vertical-rl',
      flexFlow: 'row nowrap',
      position: 'fixed',
      top: '60px',
      right: '15px',
    },
  },
  fullWidth: {
    width: '100%',
  },
  verticalHeadline: {
    padding: 3,
    marginBottom: 15,
    [tablet]: {
      marginBottom: 0,
    },
  },
  verticalRoot: {
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
    [tablet]: {
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
  },
  button: {
    WebkitAppearance: 'none',
    border: 0,
    margin: 0,
    padding: 3,
    background: 'transparent',
    fontFamily: family.arial,
    fontSize: size.tiny,
    outline: 'none',
    cursor: 'pointer',
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      [tablet]: {
        textDecoration: 'none',
      },
    },
  },
  buttonActive: {
    textDecoration: 'underline',
    [tablet]: {
      textDecoration: 'none',
    },
  },
  verticalHide: {
    display: 'none',
    [tablet]: {
      display: 'initial',
    },
  },
  mobileHide: {
    [tablet]: {
      display: 'none',
    },
  },
});

const Breadcrumbs = ({
  heading,
  sections = [],
  classes,
  fullWidth,
  vertical,
  location: { pathname },
  primary,
}) => {
  const subs = sections.map(({ val, selected }) => (
    <HashLink
      key={val}
      smooth
      to={`${pathname}#${val}`}
      className={classnames(classes.button, selected && classes.buttonActive)}
    >
      {val}
    </HashLink>
  ));

  for (let i = sections.length; i > 1; i--) {
    subs.splice(
      i - 1,
      0,
      <div className={classnames(vertical && classes.verticalHide)} key={i}>
        /
      </div>,
    );
  }

  return (
    <div
      className={classnames(
        classes.root,
        fullWidth && classes.fullWidth,
        vertical && classes.verticalRoot,
        !primary && classes.mobileHide,
      )}
    >
      <Typography
        className={classnames(vertical && classes.verticalHeadline, classes.textOrientation)}
        font="arial"
        size="tiny"
      >
        {`${heading}${subs.length > 0 ? ':' : ''}`}
      </Typography>
      {subs.length > 0 ? subs : null}
    </div>
  );
};

export default injectSheet(styles)(withRouter(Breadcrumbs));
