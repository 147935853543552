import React, { Component } from 'react';
import ReactGa from 'react-ga';
import Zespol from './Zespol';
import Divider from '../styledElements/Divider';
import Wolontarusze from './Wolontarusze';
import Stowarzyszenie from './Stowarzyszenie';

class ONas extends Component {
  componentDidMount() {
    ReactGa.pageview('/o-nas');
  }

  render = () => (
    <div>
      <Stowarzyszenie />
      <Zespol />
      <Divider hidden height={20} />
      <Wolontarusze />
    </div>
  );
}

export default ONas;
