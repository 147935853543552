import React from 'react';
import injectStyles from 'react-jss';
import classnames from 'classnames';
import Typography from '../styledElements/Typography';
import { ExternalLinkButton } from '../styledElements/LinkButton';

const styles = ({ brakePoint: { mobile }, transition: { duration, effect } }) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    margin: 10,
    alignItems: 'center',
    maxWidth: 150,
    textAlign: 'justify',
  },
  bioTrigger: {
    '&:hover>$bioContainer': {
      maxHeight: '170px',
      overflow: 'auto',
      transition: `max-height ${duration} ${effect}`,
    },
  },
  image: {
    width: '140px',
    height: '140px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '50%',
    position: 'relative',
    marginBottom: 5,
    '&:hover:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(230, 175, 46, 0.60)',
      borderRadius: '50%',
    },
  },
  nameContainer: {
    margin: '5px 0',
  },
  bioContainer: {
    margin: '5px 0',
    willChange: 'max-height',
    transition: `max-height ${duration} ${effect}`,
    maxHeight: 0,
    overflow: 'hidden',
    [mobile]: {
      maxHeight: '170px',
      overflow: 'auto',
    },
  },
});

const PhotoDescContainer = ({
  classes, imgUrl, name, lastName, shortBio, mail, phone, funct,
}) => (
  <div className={classnames(classes.root, classes.bioTrigger)}>
    <div className={classes.image} style={{ backgroundImage: `url(${imgUrl})` }} />
    <div className={classes.nameContainer}>
      <Typography font="arial" size="tiny">
        {`${name} ${lastName}`}
      </Typography>
      {phone && (
        <ExternalLinkButton style={{ padding: 0 }} to={`tel: ${phone}`}>
          {`tel: ${phone}`}
        </ExternalLinkButton>
      )}
      {mail && (
        <ExternalLinkButton style={{ padding: 0 }} to={`mailto:${mail}`}>
          {mail}
        </ExternalLinkButton>
      )}
    </div>
    <div className={classes.bioContainer}>
      {funct && (
        <Typography font="arial" size="tiny">
          {funct}
          <br />
          <br />
        </Typography>
      )}
      {shortBio && (
        <Typography font="arial" size="tiny">
          {shortBio}
        </Typography>
      )}
    </div>
  </div>
);

export default injectStyles(styles)(PhotoDescContainer);
