import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'react-jss';
import App from './App';
import './index.css';
import baseTheme from './baseTheme';
import ClientWidth from './ClientWidth';

const apiBaseUrl = 'https://crm.test18388.futurehost.pl/web/graphql';

const defaultOptions = {
  watchQuery: {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  },
  query: {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  link: new HttpLink({ uri: apiBaseUrl }),
  cache: new InMemoryCache(),
  defaultOptions,
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={baseTheme}>
      <ClientWidth>
        <App />
      </ClientWidth>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);
