import React, { Component } from 'react';
import injectStyles from 'react-jss';
import classnames from 'classnames';
import FancyMenuButton from './FancyMenuButton';
import Logo from '../../../Logo';
import SocialLinks from '../../../SocialLinks';
import ArrowIcon from '../../../styledElements/ArrowIcon';
import { InternalLinkButton } from '../../../styledElements/LinkButton';
import Divider from '../../../styledElements/Divider';

const styles = ({ palette: { red, beige, black }, font: { family } }) => ({
  root: {
    top: '100%',
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: red,
    zIndex: 10000,
    position: 'fixed',
    userSelect: 'none',
    transition: 'top 0.2s linear',
  },
  boxShadow: {
    boxShadow: '0px 0px 50px 40px rgba(255,255,255,1)',
  },
  top: {
    top: 0,
  },
  centerContainer: {
    textAlign: 'center',
    margin: '20px auto',
    fontFamily: family.questa,
    fontSize: '7vw',
    color: beige,
  },
  itemsContainer: {
    width: '100%',
    height: '90%',
    display: 'flex',
    flexFlow: 'column',
    padding: '20px 30px 40px',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxHeight: '95vh',
    overflowY: 'auto',
  },
  inlineContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    paddingLeft: '5px',
    position: 'relative',
    justifyContent: 'center',
  },
  arrow: {
    height: '9px',
  },
  arrowRoot: {
    transform: 'translateY(2px)',
  },
  menuItemsContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '80%',
    height: 230,
    justifyContent: 'space-between',
  },
  divider: {
    backgroundColor: black,
    opacity: 0.4,
    margin: '1px 0',
  },
  stopMove: {
    overflow: 'hidden',
    height: '100vh',
    width: '100vw',
    position: 'fixed',
  },
});

class MobileMenu extends Component {
  constructor(props) {
    super(props);
    this.onDrag = this.onDrag.bind(this);
    this.onDragStop = this.onDragStop.bind(this);
    this.state = {
      isOpen: false,
      bodyHeight: 0,
      position: {
        x: 0,
        y: 0,
      },
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    setTimeout(() => {
      this.handleResize();
    }, 300);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  onDrag(e, position) {
    e.preventDefault();
    e.stopPropagation();
    const { x, y } = position;
    this.setState({ position: { x, y } });
  }

  onDragStop(e, position) {
    const { x, y } = position;
    const { isOpen, bodyHeight } = this.state;
    const show = (doIt) => {
      this.setState({ position: { y: doIt ? -bodyHeight : 0, x }, isOpen: doIt });
    };
    if (isOpen) {
      show(-y > bodyHeight * 0.8);
    } else {
      show(-y > bodyHeight * 0.1);
    }
  }

  handleResize = () => this.setState({
    bodyHeight: (document.querySelector('#draggableMenu').clientHeight * 10) / 11,
  });

  refCallback = (element) => {
    if (element) {
      this.handleResize();
    }
  };

  toggleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { isOpen, bodyHeight } = this.state;
    this.setState({
      isOpen: !isOpen,
      position: {
        x: 0,
        y: isOpen ? 0 : -bodyHeight,
      },
    });
  };

  closeMenu = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    window.scrollTo(0, 0);
    this.setState({
      isOpen: false,
      position: {
        x: 0,
        y: 0,
      },
    });
  };

  render = () => {
    const {
      classes, menu, pathname, column,
    } = this.props;
    const { bodyHeight, position } = this.state;
    return (
      <div
        ref={this.refCallback}
        id="draggableMenu"
        className={classnames(classes.root, position.y < 0 && classes.top, !column && classes.boxShadow)}
      >
        <FancyMenuButton
          onClick={this.toggleOpen}
          openedFactor={(bodyHeight ? -position.y / bodyHeight : 0) || 0}
        />
        <div className={classes.itemsContainer}>
          <div className={classes.centerContainer}>
            <Logo color="dark" />
            <div>Festiwal Otwarte</div>
            <div>Mieszkania Kraków</div>
          </div>
          <div className={classes.menuItemsContainer}>
            {menu.map(menuItem => (
              <span key={menuItem.name}>
                <div className={classes.inlineContainer}>
                  {menuItem.link === pathname && (
                    <ArrowIcon classes={{ root: classes.arrowRoot, arrow: classes.arrow }} />
                  )}
                  <InternalLinkButton
                    style={{ fontSize: 16 }}
                    active={menuItem.link === pathname}
                    to={menuItem.link}
                    alt={menuItem.name}
                    onClick={this.closeMenu}
                  >
                    {menuItem.name}
                  </InternalLinkButton>
                </div>
                <Divider classes={{ visible: classes.divider }} />
              </span>
            ))}
          </div>
          <SocialLinks />
        </div>
      </div>
      // </Draggable>
    );
  };
}

export default injectStyles(styles)(MobileMenu);
