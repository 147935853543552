import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';

const withPlayerLink = WrappedComponent => ({ blockId, ...props }) => {
  const FILE_QUERY = gql`
  query {
    blockContentQuery(filter: {conditions: {field: "field_block_id", value: "${blockId}", operator: EQUAL}}) {
      entities {
        ... on BlockContentLinkBlock {
          fieldLinkDoVimeo {
            uri
          }
        }
      }
    }
  }
`;
  return (
    <Query query={FILE_QUERY}>
      {({ data, loading }) => {
        const url = _.get(data, 'blockContentQuery.entities[0].fieldLinkDoVimeo.uri', undefined);
        return (
          <WrappedComponent
            {...props}
            loading={loading}
            url={url}
          />
        );
      }}
    </Query>
  );
};

export default withPlayerLink;
