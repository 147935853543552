import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';

const withDataQuery = WrappedComponent => ({ blockId = 'opis_dane_stowarzyszenia', ...props }) => {
  const BLOCK_BY_ID = gql`
      query {
        blockContentQuery(filter: {conditions: {field: "field_block_id", value: "${blockId}", operator: EQUAL}}) {
          entities {
            ... on BlockContentCelledBlock {
              id
              fieldCell {
                value
              }
              fieldBlockId
            }
          }
        }
      }
    `;
  return (
    <Query query={BLOCK_BY_ID}>
      {({ data, loading }) => {
        const cells = _.get(data, 'blockContentQuery.entities[0].fieldCell', '');
        return <WrappedComponent {...props} loading={loading} cells={cells} />;
      }}
    </Query>
  );
};

export default withDataQuery;
