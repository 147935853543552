import React from 'react';
import injectStyles from 'react-jss';
import classnames from 'classnames';

const styles = ({ palette: { green, background } }) => ({
  root: {
    maxWidth: 1100,
    margin: 'auto',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
  },
});
const CenterContainer = injectStyles(styles)(({ children, classes, className }) => (
  <div className={classnames(classes.root, className)}>{children}</div>
));

export default CenterContainer;
