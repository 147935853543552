import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import React from 'react';

const EVENT_DATES_QUERY = gql`
  query DatyWydarzen {
      datyWydarzenGraphql1View {
      results {
        uuid
        fieldDateEnd {
          value
        }
        fieldDateStart {
          value
        }
      }
    }
  }
`;

const withQuery = WrappedComponent => props => (
  <Query
    query={EVENT_DATES_QUERY}
  >
    {({ data }) => {
      if (!data || !data.datyWydarzenGraphql1View) return null;
      return (
        <WrappedComponent {...props} eventDates={data.datyWydarzenGraphql1View.results} />
      );
    }}
  </Query>
);

export default withQuery;
